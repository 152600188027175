a.attachment {
  color: $gray;
  &:hover {
	text-decoration: none;
  }
}

i.attachment {
  background-repeat: no-repeat
  height: 22px;
  width: 22px;
  display: inline-block;
  background-size: contain;
}



table.correspondence {
  input[type="radio"],
  input[type="checkbox"] {
	margin: 0;
  }
}

.modal {
	.modal-body {
		&.bulk-letter {
			display: block;
			overflow: hidden;
			&.with-infobox {
				height: calc(100vh - 162px);
			}
			&.without-infobox {
				height: calc(100vh - 100px);
			}
		}
	}
}
