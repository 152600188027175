.sticky_container {
	position: absolute;
	left: 100%;
	top: 0;
	width: 50%;
	padding: 20px 0 0;
	.box {
		position: relative;
		left: 0;
		width: 100%;
		margin: 0 0 20px 0;
		.fa-chevron-right {
			display: none;
		}
		.handle {
			position: absolute;
			right: 100%;
			top: 0;
			width: 30px;
			height: 40px;
			background: #e7eaec;
			cursor: pointer;
			i {
				width: 100%;
				padding: 13px 0;
			}
		}
		.inner {
			border-bottom: 4px solid #e7eaec;
			border-left: 4px solid #e7eaec;
			background: #fff;
			.head {
				border: 0;
				padding: 15px;
				border-bottom: 1px solid #ccc;
				i {
					float: right;
					cursor: pointer;
				}
			}
			.body {
				padding: 15px;
				overflow: hidden;
				display: none;
			}
		}

		&.active {
			left: -100%;
			.body {
				display: block;
			}
			.fa-chevron-right {
				display: block;
			}
			.fa-chevron-left {
				display: none;
			}
		}
		&.animated {
			-webkit-transition: left .5s;
			-moz-transition: left .5s;
			-ms-transition: left .5s;
			-o-transition: left .5s;
			transition: left .5s;
		}
	}
}