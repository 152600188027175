@require "../core/variables";

.form-horizontal {
	.modal {
		display: block;
		position: static;
	}
	.ui-select-container.dropup .btn,
	.ui-select-container.dropdown .btn {
		height: 34px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.form-expand {
		border-top: 1px solid #dedede !important;
	}
	.ui-select-match-text, .ui-select-placeholder {
		margin: 0;
	}
	.dropup.ui-select-multiple .btn,
	.dropdown.ui-select-multiple .btn {
		height: auto;
		.ui-select-match-close {
			position: relative;
			top: 2px;
		}
	}
}

.template-list {
	max-width: 500px;
	margin: 0 auto;
	padding: 20px;
}
