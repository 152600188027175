.two-level-list {
  .first-level {
	.header {
	  font-weight: bold;
	  background: #e8e2a1;
	  border-bottom: 1px solid #ccc;
	  padding: 5px 20px;
	}
  }

  .second-level {
	.alert {
	  margin-bottom: 10px;
	}
	.header {
	  background: #f3f0cd;
	  padding: 5px 20px;
	  border-bottom: 1px solid #ddd;
	}
  }

  .list-item {
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	padding: 8px 20px;

	&:hover {
	  background-color: #ebf2e6;
	  cursor: pointer;

	  a {
		text-decoration: underline;
	  }
	}

	a {
	  color: #000;
	  text-decoration: none;

	  &:hover {
		text-decoration: underline;
	  }
	}
  }
}


