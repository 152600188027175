$icon-base-path = '../../img/icons'

/*
base style that is used when generated class name (based on extension) is not listed below
*/
.file_icon_default {
  background-image: url($icon-base-path + '/File.svg');
}

.deleted_file_icon_default {
  background-image: url($icon-base-path + '/icon-delete_file.png');
}

.file_icon_doc {
  background-image: url($icon-base-path + '/document.svg');
}

.deleted_file_icon_doc {
  background-image: url($icon-base-path + '/documentDeleted.svg');
}

.file_icon_sent {
  background-image: url($icon-base-path + '/Mail-3.svg');
}
