treecontrol,
[treecontrol] {
	/* prevent user selection */
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* default */
	font-family: Verdana, Helvetica, Arial, sans-serif;
	font-size: 13px;
	color: $default-text-color;
	text-decoration: none;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		border: none;
		li {
			position: relative;
			padding: 0 0 0 20px;
			line-height: 20px;
			&.tree-expanded,
			&.tree-collapsed {
				i.tree-leaf-head {
					display: none;
				}
			}
			&.tree-leaf i {
				&.tree-branch-head {
					display: none;
				}
				&.tree-leaf-head {
					display: inline;
				}
			}
			i.tree-branch-head {
				cursor: pointer;
			}
			.tree-label {
				display: inline;
				.underline-on-hover {
					cursor: pointer;
					+ small {
						cursor: default;
						span {
							cursor: default;
						}
					}
				}
			}
		}
	}
	&.tree-classic {
		li.tree-expanded {
			i.tree-branch-head {
				padding: 0 6px;
				background: url("../../img/icons/chevron-down.svg") no-repeat 2px 4px;
				background-size: 40% 30%;
				margin: 0 10px 0 0;
			}
		}
		li.tree-collapsed {
			i.tree-branch-head {
				padding: 0 6px;
				background: url("../../img/icons/chevron-right.svg") no-repeat 0 4px;
				background-size: 25% 50%;
				margin: 0 10px 0 0;
			}
		}
		li.tree-leaf i.tree-leaf-head {
			padding: 0 10px;
		}
		li .tree-selected {
			background-color: $item-selected-color;
		}
	}
	&.tree-light {
		li.tree-expanded {
			i.tree-branch-head {
				padding: 0 10px;
			}
		}
		li.tree-collapsed {
			i.tree-branch-head {
				padding: 0 10px;
			}
		}
		li.tree-leaf i.tree-leaf-head {
			padding: 0 10px;
			width: 16px;
			height: 16px;
			background: none no-repeat;
		}
	}
	.tree-dark {
		color: #ddd;
		li.tree-expanded {
			i.tree-branch-head {
				padding: 0 10px;
			}
		}
		li.tree-collapsed {
			i.tree-branch-head {
				padding: 0 10px;
			}
		}
		li.tree-leaf i.tree-leaf-head {
			padding: 0 10px;
			width: 16px;
			height: 16px;
			background: none no-repeat;
		}
	}
}

.tree-container,
.list-container {
	overflow-y: visible;
	.tree-row > div {
		overflow: hidden;
	}
	.tree-item-status,
	.list-text-right {
		float: right;
		margin-right: 0;
		position: relative;
		top: 3px;
		max-width: 30%;
		text-align: right;
	}
	.list-content {
		float: left;
		small {
			position: relative;
			top: -5px;
			margin: 0 0 0 5px;
			font-size: 14px;
			color: #9e9e9e;
			.separator-both {
				margin: 0 5px;
			}
			.tag {
				margin: 0 0 0 10px;
				border: 1px solid #cccccc;
				padding: 1px 4px;
				border-radius: 3px;
				color: $default-text-color;
				background: #e6e6e6;
				position: relative;
				top: 0;
				margin-right: 1px;
			}
		}
	}
	.panels & {
		overflow-y: auto;
		border-right: 1px solid #ccc;
		-webkit-overflow-scrolling: touch;
	}
	.tree-header {
		padding: 5px 0 5px 20px;
		position: relative;
		background-color: #f0edc9;
		border-bottom: 1px solid #DEDEDE;
		min-height: 30px;
	}
	.tree-checkboxes {
		position: absolute;
		right: 0;
		top: 5px;
		width: 180px;
		> div {
			margin: 0 10px 0 0;
			width: 35px;
		  	height: 26px;
			text-align: right;
			float: left;
			input[type="radio"],
			input[type="checkbox"] {
				margin: 8px 0 0;
				float: right;
			}
		}
	}
	.tree-classic {
		.tree-dropdown {
			position: absolute;
			right: 0;
			top: 5px;
			margin: 0 10px 0 0;
		}
		.tree-checkboxes {
			> div {
				min-height: 1px;
			}
		}
		.tree-selected div {
			background: inherit;
		}
	}
	.position-relative {
		position: relative;
	}
	.toggler {
		background-color: #dceff3;
		padding: 5px;
		border-bottom: 1px solid #dedede;

		> i {
			cursor: pointer;
			display: block;
			width: 40px;
			height: 30px;
			background-position: center center !important;
			background-repeat: no-repeat;
			background-size: 25% 50%;

			background-image: url("../../img/icons/chevron-right.svg");

			&.expanded {
				background-image: url("../../img/icons/chevron-down.svg");
				background-size: 40% 30%;
			}
		}
	}
	.panel-header {
		min-height: 61px;
		background: #deeff3;
		border-bottom: 1px solid #cccccc;
		padding: 13px 0 13px 20px;
	}
	treecontrol.hasDropdown .tree-row > div {
		padding-bottom: 7px;
	}
	.tree-row .dropdown {
		position: absolute;
		right: 10px;
		top: 6px;
		border-left: 1px solid #ccc;
		width: 31px;
		height: 30px;
		.dropdown-menu {
			margin: 0;
			border-radius: 0;
			border: 1px solid #ccc;
			padding: 0;
			box-shadow: 0 0 0;
			white-space: nowrap;
			li {
				border-bottom: 1px solid #ccc;
				//padding: 8px !important;
				padding: 8px 25px 8px 8px !important;
				cursor: pointer;
				ul {
					position: absolute;
					right: -100%;
					width: 100%;
					top: -1px;
					background: #fff;
					border: 1px solid #ccc;
					display: none;
					li {
						&:last-child {
							border-bottom: 0;
						}
					}
				}
				&:hover {
					background: $item-hover-color;
					> span {
						color: #43b02a;
					}
					ul {
						display: block;
					}
				}
			}
		}
		.dropdown-menu-responsive {
			@media (max-width: $screen-sm-max) {
				left: auto;
				right: 0;
			}
		}
		.btn {
			background-color: #fff;
			border: 0;
			border-radius: 0;
			height: 30px;
			width: 100%;
			padding: 0 11px 2px;
			&:after {
				content: '';
			}
			&:hover {
				background-color: #fff;
			}
		}
		&.open .btn {
			background-color: #fff;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		margin: 2px 10px 0 0;
	}
	.checkbox-placeholder {
	  	width: 28px;
	  	display: inline-block;
	}
	.tree-label i + div,
	.table i + div {
		position: relative;
		top: 0;
		height: 21px;
		overflow: hidden;
		word-break: break-all;
		&.nowrap {
			height: auto;
			white-space: nowrap;
			text-overflow: ellipsis;

		}
	}
}

/* when dropdown arrows always pulled to the right */
.dropdown-menus-right {
	.dropdown-menu {
		left: auto !important;
		right: 0 !important;
	}
}


@media (min-width: $screen-sm-min) {
	.tree-container,
	.list-container {
		.tree-row > div {
			overflow: visible;
		}
		/*treecontrol.hasDropdown .tree-row > div {
			padding-bottom: 0;
		}*/
	}
}

/* dropdown inside tree control */
@media (min-width: $screen-md-min) {
	.tree-container,
	.list-container {
		treecontrol.hasDropdown {
			.tree-row {
				> div {
					padding-bottom: 7px;
				}
			}
			.tree-item-status,
			.list-text-right {
				margin-right: 15px;
			}
		}
		.tree-row .dropdown {
			right: auto;
			margin-left: 10px;
			display: none;
		}
	}
}

treecontrol,
.treecontrol {
	font-family: "interface", sans-serif !important;
	font-size: 14px;
	small {
		font-size: 14px;
		color: #9e9e9e;
		.separator-both {
			margin: 0 5px;
		}
		.separator-left {
			margin: 0 0 0 10px;
		}
		.tag {
			border: 1px solid #cccccc;
			padding: 1px 4px;
			border-radius: 3px;
			color: $default-text-color;
			background: #e6e6e6;
		}
	}
	.tree-row {
		//cursor: pointer;
		min-height: 42px;
		.active {
			background-color: $item-hover-color;
			&:hover {
				a {
					text-decoration: underline;
				}
			}
			.underline-on-hover:hover {
				text-decoration: underline;
			}
		}
	}
	&.hasDropdown {
		.tree-row > div {
			padding-right: 51px;
			padding-bottom: 0;
		}
		@media (min-width: $screen-sm-min) {
			.tree-row {
				> div {
					padding-bottom: 7px;
				}
				.dropdown {
					color: $default-text-color;
					font-weight: normal;
				}
				> .warning {
					background: #F3DF81;
					&.active {
						background: #F0D968;
					}
					small[additional-information] {
						color: #864D30;
					}
				}
				/*1. border: #CDAD14
				2. On medicin liste we have a similar alert row, that today is either orange or red. We need to provide same styling here, and row should have same height. So the orange becomes yellow - and the red will be with bg #FF5353 and line #E80000*/
			}
		}
	}
	> ul > li {
		border-top: 0;
		padding: 0;
	}
	&.tree-classic {
		li {
			.tree-selected {
				font-weight: normal;
				a {
					text-decoration: none;
				}
			}
			&.tree-leaf i.tree-leaf-head {
				padding: 0 16px 0 17px !important;
			}

			i.tree-branch-head {
				background-position: center center !important;
				width: 30px;
				height: 20px;
				margin: 0 !important;
			}
		}

	}
	.tree-label {
		> div,
		> span > div {
			display: inline-block;
		}
		a {
			color: $default-text-color;
			&:hover,
			&:active {
				text-decoration: none;
			}
		}
	}
	li {
		padding: 0 !important;
		> div > div {
			border-bottom: 1px solid #dedede;
		}
		.dropdown-menu li {
			padding-left: 0;
		}
		&.tree-expanded i.tree-branch-head,
		&.tree-collapsed i.tree-branch-head {
			display: inline-block;
		}
		&.tree-leaf i.tree-branch-head {
			display: none;
		}
	}
}

@media (min-width: $screen-sm-min) {
	treecontrol,
	.treecontrol {
		.tree-label {
			.dropdown {
				/*display: none;*/
			}
		}
		.tree-row .active > .tree-label .dropdown {
			display: inline-block;
		}
	}

	.table {
		tr {
			&:hover {
				.dropdown {
					display: inline-block;
					&.ui-select-container {
						display: block;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	treecontrol,
	.treecontrol {
		.tree-label {
			.dropdown {
				display: none;
			}
		}
		&.hasDropdown {
			.tree-row > div {
				padding-right: 0;
			}
		}
	}

	.panels {
		.dropdown {
			display: none;
		}
	}
}

/*.dropup,
.dropdown {
	top: 5px;
	position: absolute;
	right: 10px;
	width: 31px;
	height: 30px;
	border-left: 1px solid #c9c9c9;
	.btn {
		background-color: transparent;
		border: 0;
		border-radius: 0;
		height: 30px;
		width: 100%;
		padding: 0 11px 2px;
		&:after {
			content: '';
		}
		&:hover {
			background-color: #fff;
		}
	}
	table & {
			  position: initial;
		  }
}*/

.tree-expanded {
	padding-bottom: 0;
}

.isSelected {
	background: #e8e8e8;
	li {
		background: #fff;
	}
}

.hasCheckboxes .tree-row > div,
.hasCheckboxes td {
	padding-right: 180px !important;
}

