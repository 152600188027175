@require "core/variables";

.criteria-button {
	padding: 15px 10px;
	.dropdown-menu {
		max-height: 272px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 175px;
	}
	i.add-icon {
		background-size: 18px 18px !important;
		width: 20px !important;
		height: 19px !important;
	}
}

.criteria-section-message {
	padding: 0 20px;
}

.criteria-group {
	padding: 0 10px;
	display: table;
	width: 100%;
	h3 {
		font-size: 14px;
		font-weight: bold;
		color: #000;
	}
	.criteria-group-simple {
		display: flex;
		display: -ms-flex;
		align-items: center;
		> div {
			margin: 9px 0;
			+ .icon {
				margin: 4px 15px 0;
			}
		}
		+ .form-group {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.criteria-group-row {
	    margin-bottom: 3px;
	}
	.criteria-element {
		float: left;
		width: 100%;
		min-height: 35px;
		&:first-child {
			padding-right: 28px;
		}
		&:last-child.remove-icon {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			width: auto;
		}
		&.criteria-checkbox,
		&.criteria-radio {
			padding: 10px 0 10px 6px;
		}
	}
	.control-label {
		width: 100%;
		padding: 0 10px 0 0;
	}
	.ui-select-container {
		width: 100%;
	}
	.remove-icon {
		width: 13px;
		height: 13px;
		margin: 10px 0 6px;
		background-size: 100%;
	}
	.additional-options > * {
		margin: 7px 0 0 0;
	}
	.icon {
		margin: 4px 15px 0;
	}
	&.sort-table {
		.criteria-element {
			padding-right: 0;
			&:last-child {
				position: static;
			}
		}
	}
}

.criteria-group .form-group.has-error {
  padding-left: 20px !important;
  margin-left: -10px;
  margin-right: -10px;
  padding-right: 10px !important;
}

@media (min-width: $screen-sm-min) {
	.criteria-button,
	.criteria-group {
		padding-right: 20px;
		padding-left: 20px;
		+ .sort-order {
			border-top: 1px solid #eee;
		}
	}

  	.criteria-group .form-group.has-error {
		padding-left: 30px !important;
		margin-left: -20px;
		margin-right: -20px;
		padding-right: 20px !important;
  	}

	.criteria-group {
		.criteria-element {
			width: auto;
			margin-right: 15px;
			&:first-child {
				padding-right: 0;
			}
			&:last-child.remove-icon {
				position: static;
				top: auto;
				right: auto;
				width: auto;
			}
			&.narrow {
				.ui-select-container {
					width: 150px;
				}
			}
			&.wide,
			&.criteria-dropdown {
				.ui-select-container {
					width: 350px;
				}
			}
		    &.extra-wide {
			  min-width: 350px;
			  width: calc(100% - 450px);
			  .ui-select-container {
				min-width: 350px;
				.ui-select-match-text {
				  width: calc(100% - 10px);
				}
			  }
			}
			&.criteria-checkbox,
			&.criteria-radio {
				padding-top: 7px;
			}
			&.criteria-textarea {
				width: 404px;
				textarea {
					width: 350px;
				}
			}
			&.criteria-text {
				width: 416px;
				input {
					width: 350px;
				}
			}
			.ui-select-container {
				float: left;
				.ui-select-match-text {
					width: 85%;
				}
			}
		}
		.control-label {
			padding-top: 7px;
			float: left;
			width: auto;
			+ div {
				float: left;
			}
		}
	}
}

.no-preferences-form {
	background-color: #DFDFDF;
  	display: flex;
  	justify-content: center;
  	padding: 150px 0 0 0;

  div {
	font-size: 20px;
	p {
	  font-weight: bold;
	}
  }
}

.no-preferences-form-background {
  background-color: #DFDFDF;
  position: absolute;
  height: 10000px;
  width: 120%;
  z-index: -1;
}

.sort-order .list-group li.list-group-item.editable-dashboard-widget {
  &.react-widget {
	  border: unset;
	  border-radius: unset;
	  background-color: unset;
	  padding: unset;
	  min-height: unset;

	  .sort-table {
		padding: unset;
	  }
	}
}
