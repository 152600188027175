.loggout-page {

  .login-page {
	width: 100%;
  }

  .login-page .col-sm-4,
  .login-page .col-md-3 {
	height: 60vh;
	padding: 0;
  }

  .login-page .col-sm-8,
  .login-page .col-md-9 {
	height: 40vh;
	position: relative;
  }

  @media (max-width: 767px) {
	.row {
	  margin-left: 0px;
	}

	.container-fluid {
	  padding: 0;
	}
  }

  @media (min-width: 1200px) {
	.row {
	  margin-left: -30px;
	}
  }

  @media (min-width: 768px) {
	.login-page {
	  width: auto;
	}

	.login-page .col-sm-4,
	.login-page .col-md-3 {
	  padding-right: 15px;
	  padding-left: 15px;
	  background: url("../../img/kmd_nexus_logo.svg");
	  background-size: 110px;
	  background-repeat: no-repeat;
	  background-position: center 90%;
	  background-color: #fff;
	}

	.login-page .col-sm-4,
	.login-page .col-md-3,
	.login-page .col-sm-8,
	.login-page .col-md-9 {
	  height: 100vh;
	}
  }

  .login-container {
	padding: 40px 20px 0;
  }

  .login-container input {
	width: 100%;
  }

  input[type=text],
  input[type=password] {
	border: 0;
	border-bottom: 1px solid #9e9e9e;
	height: 61px;
	padding: 0 0 0 70px;
	outline: 0;
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 35px;
	border-radius: 0;
	box-shadow: none;
  }

  .user-name-input {
	background-image: url("../../img/icons/Male.svg");
  }

  .sms-input {
	background-image: url("../../img/icons/Smartphone-3.svg");
  }

  input[type=text]:active,
  input[type=text]:focus,
  input[type=password]:active,
  input[type=password]:focus {
	border-bottom: 1px solid #3898ec !important;
	outline: 0;
	box-shadow: none !important;
  }

  .login-input {
	margin-bottom: 10px;
  }

  .login-container input[type=password] {
	background-image: url("../../img/icons/Password-shopping.svg");
  }

  .login-container button {
	margin: 30px 0 0;
	border: 0;
	background: #43b02a;
	height: 60px;
	color: #fff;
	font-size: 16px;
	transition: .3s background;
	width: 100%;
	border-bottom: 3px solid #34911f;
  }

  .login-container input[type=submit]:hover {
	background: #000;
	transition: .3s background;
  }

  .cb-slideshow {
	margin: 0;
	list-style: none;
  }

  .cb-slideshow,
  .cb-slideshow:after {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
  }

  .cb-slideshow:after {
	content: '';
  }

  .cb-slideshow li span {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: transparent;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: none;
	z-index: 0;
  }

  .cb-slideshow li span {
	background-image: url(../../img/1280x720/login_bg_02.jpg)
  }

  /* Show at least something when animations not supported */
  .no-cssanimations .cb-slideshow li span {
	opacity: 1;
  }
}
