@require "bootstrap/variables";

.fast-organization-tree {
  .tree-row {
	min-height: 43px;
	border-bottom: 1px solid #dedede;
	position: relative;
	padding: 8px 55px 7px 0;
	&.warning {
	  background-color: #f3df81;
	  &:hover {
		background-color: #f0d968;
	  }
	}
	> div {
	  overflow: hidden;
	}
	.btn-group {
	  font-weight: 100;
	}
	.widget & {
	  .btn-group {
		right: 10px;
		display: block;
	  }
	}
	&:hover {
	  background-color: #ebf2e6;
	  .dropdown {
		display: inline-block;
		.ui-select-container {
		  display: block;
		}
	  }
	}
	.tree-label {
	  display: inline;
	  > span {
		> a {
		  color: #000;
		  font-size: 14px;
		}
		> div {
		  max-width: 70%;
		  display: inline-block;
		  top: 1px;
		  &.tree-item-status {
			top: 3px;
			margin-right: 0;
			margin-left: 10px;
		  }
		  > span {
			+ small {
			  font-size: 14px;
			  color: #9e9e9e;
			}
		  }
		}
	  }
	  .toggle-icon {
		width: 27px !important;
		height: 22px !important;
		background-repeat: no-repeat;
		position: relative;
		top: 2px;
		cursor: pointer;
		margin: 0;
		background-position: center center;
		&.collapsed {
		  background-image: url("../../img/icons/chevron-right.svg");
		  background-size: 6px 12px;
		}
		&.expanded {
		  background-image: url("../../img/icons/chevron-down.svg");
		  background-size: 12px 6px;
		}
	  }
	}
	&.unread {
	  .tree-label {
		span {
		  > div {
			font-weight: bold;
		  }
		}
	  }
	}
  }
  .widget & {
	.tree-row {
	  padding-right: 55px;
	}
  }
  .hasDropdown .tree-row > div {
	padding-bottom: 7px;
  }
}

@media (min-width: $screen-md-min) {
  .fast-organization-tree {
	.tree-row {
	  padding-right: 15px;
	  .btn-group {
		right: auto;
	  }
	}
  }
}
