nx-messages {
	position: relative;
	left: 12px;
	top: 5px;
}

nx-button-dropdown {
	.caret-label-left {
		padding-right: 4px;
	}
	> .btn-group {
		display: inline-block;
	}
}

nx-dropdown {
  .caret-label-left {
	padding-right: 4px;
  }
}

nx-pagination,
nx-pagination-pagenumbers,
nx-pagination-previous-next {
	.inline {
		display: table-cell
		> span > div {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.block {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		flex-direction: column-reverse;
		flex-flow: rtl;
		height: 70px;
	}
	> .inline .pagination, > .block .pagination {
		margin: 4px 10px;
	}
	> .inline .pagination-info, > .block .pagination-info {
		margin-bottom: 5px;
	}
	.pagination-block {
		display: block;
		-ms-flex: 1;
		> .single-page{
		  margin-top:10px;
		}
	}
}


nx-pagination {
	&.reversed-order {
		ul {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			li {
				transform: rotate(180deg)
			}
			li:first-child {
				order: 2;
				margin-left: 5px;
			}
		}
	}
}

nx-pagination-pagenumbers {
	.reversed-order {
		ul {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			li {
				transform: rotate(180deg)
			}
			li:first-child {
				order: 2;
				margin-left: 5px;
			}
		}
	}
	> .inline .pagination-info, > .block .pagination-info {
		margin-right: 10px;
	}
}

nx-dialog-header {
	.dialog-header {
		background: #d7e7cd;
		padding: 0 75px 0 20px;
		overflow: hidden;
		min-height: 86px;
		border-bottom: 1px solid #c9d1c2;
		position: relative;
		h2 {
			margin: 29px 10px 0 0;
			padding: 0 10px 1px 0;
			font-weight: bold;
			font-size: 28px;
		}
	}

	.dialog-header-layout-compact {
		.dialog-header {
			min-height: 61px;

			h2 {
				margin: 18px 10px 0 0;
				font-size: 23px;
			}

			p {
				font-size: 16px;
			}
		}
	}
}

