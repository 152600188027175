.fmk-sync {
	.dashed {
		border-top: 1px dashed rgba(0, 0, 0, 0.25);
		border-left: 1px dashed rgba(0, 0, 0, 0.25);
		height: 10px;
		width: 10%;
		float: left;
		position: relative;
		top: 10px;
		&.right {
			border-left: 0;
			border-right: 1px dashed rgba(0, 0, 0, 0.25);
			float: right;
		}
	}
	.text {
		width: 80%;
		float: left;
		text-align: center;
	}
}

.reconciliation-container {

	.text {
		width: 100%;
		text-align: center;
	}

	.fmk-header {
		min-height: 40px;
	}

	.header-icon {
		width: 14px;
		height: 14px;
	}

	.toggle {
		background-image: url('../../img/icons/arrowRight.svg');
		background-repeat: no-repeat;
		background-size: 30% 60%;
		background-position: center center;
		width: 20px;
		height: 20px;
		float: left;
		cursor: pointer;
		margin-right: 10px;
		padding-top: 30px;
		&.child-shown {
			background-image: url("../../img/icons/arrowDown.svg");
			background-size: 60% 30%;
		}
	}

	.toggle-section-header {
		padding: 10px;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		background-color: #eaf6f9;
		height: 51px;
		.toggle-header-text {
			font-size: 15px;
			font-weight: 400;
			padding-right: 15px;
		}
	}
}

.two-column-list {
	.header {
		background: #dceff3;
		padding: 17px 20px;
		min-height: 54px;
		+ .header {
			padding-top: 0;
			min-height: 44px;
		}
		.title,
		.ui-select-container {
			float: left;
		}
		.title {
			width: 100%;
			word-break: break-all;
			margin: 0 0 10px 0;
		}
		.only-text {
			padding-top: 7px;
		}
		.ui-select-container {
			width: 49%;
			.btn {
				margin: 0;
			}
			+ .ui-select-container {
				margin-left: 2%;
			}
		}
		&.has-dropdowns {
			overflow: visible;
			min-height: 74px;
			padding: 10px 20px 0 20px;
			.ui-select-container {
				.ui-select-match {
					> span {
						border-radius: 3px;
						span {
							margin: 1px 0;
						}
					}
				}
			}
			&.last-header {
				min-height: 54px;
			}
		}
	}
	.subheader {
		background: #f3f0cd;
		padding: 5px 20px;
		border-top: 1px solid #c9c9c9;
		border-bottom: 1px solid #c9c9c9;
		font-weight: bold;
	}
	.col-md-6 {
		&:first-child {
			.header.has-dropdowns,
			.waiting-list-container {
				border-right: 1px solid rgba(0, 0, 0, 0.25);
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.two-column-list {
		.subheader {
			span {
				display: none;
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.two-column-list {
		.header {
			.title {
				width: 25%;
				word-break: break-all;
				margin-top: 6px;
				margin-bottom: 0;
			}
			.ui-select-container {
				width: 35%;
				margin-right: 10px;
				.btn {
					margin: 0;
				}
				+ .ui-select-container {
					margin-left: 0;
				}
			}
			&.has-dropdowns {
				min-height: 44px;
			}
		}
	}
}

.fmk-list,
.waiting-list {
	padding: 0 20px;
	overflow: hidden;
	p {
		margin: 10px 0;
		text-align: center;
	}
	.fmk-card-min-height {
		min-height:150px;
	}
	.fmk-card,
	.waiting-list-card {
		background: #fff;
		margin: 20px 0;
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0.4);
		border-radius : 5px;
		overflow: hidden;
		transition: box-shadow .3s;
		margin-left:5px;
		margin-right:5px;
		.fmk-header,
		.waiting-list-header {
			padding: 10px;
			background-color: #b3dde6;
			font-weight: bold;
			> span:last-child {
				float: right;
			}
		}
		.waiting-list-row {
			div {
				width: 25%;
				float: left;
				border-top: 1px solid rgba(0, 0, 0, 0.15);
				padding: 5px 10px;
				font-weight: bold;
				+ div {
					width: 75%;
					font-weight: normal;
				}
			}
		}
		.fmk-row {
		  > div {
			width: 25%;
			float: left;
			border-top: 1px solid rgba(0, 0, 0, 0.15);
			padding: 5px 10px;
			font-weight: bold;
			word-wrap: break-word;
			+ div {
			  width: 75%;
			  font-weight: normal;
			}
		  }
		}
		.fmk-list-row {
		  > div {
			width: 25%;
			float: left;
			padding: 5px 10px;
			font-weight: bold;
			+ div {
			  width: 75%;
			  font-weight: normal;
			}
		  }
		}
		&:hover {
			box-shadow: rgba(0, 0, 0, 0.35) 0 0 3px 0;
			transition: box-shadow .3s;
			.fmk-header {
				text-decoration: underline;
			}
		}
	}
}

.fmk-hide-unchanged-medication {
  width: 190px;
}

.waiting-list-search {
	display inline-block;
	margin-right: 7px;
	width: calc(100% - 56px);
	+ .btn {
		margin: 0;
	  	position: absolute;
	}
}

@media (min-width: $screen-lg-min) {
	.waiting-list-search {
		margin-left: calc(25%);
		width: calc(70% - 44px);
	}
}

.fmk-list {
	background: #fff;
	.seperator {
		border-left: 1px solid #c9c9c9;
	}
}

.fmk-highlight {
	background: #fff9ba !important;
	background-color: #fff9ba !important;
}


/* Waiting List */
.waiting-list-container {
	overflow-x: hidden;
	overflow-y: auto;
}

.waiting-list {
	background: #fff;
	.waiting-list-card {
	  	.waiting-list-row {
			i {
			  	width: 15px;
			  	height: 15px;
			  	display: inline-block;
			  	background-size: contain;
			  	margin: 0 5px 0 0;
			  	position: relative;
			  	top: 2px;
			  	background-repeat: no-repeat;
			  	background-image: url("../../img/icons/stop--2-transparent.svg");
			}
		  	a {
			  	color: #000000;
			}
		}
		.waiting-list-header {
			overflow: hidden;
			.toggle {
				float: left;
				width: 20px;
				height: 20px;
				margin: 0 15px 0 0;
				background-image: url("../../img/icons/arrowDown.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 60% 30%;
			}
			input[type="radio"] {
				width: 15px;
				height: 15px;
				margin: 0 5px 0 0;
				position: relative;
				top: 3px;
				cursor: pointer;
			}
		}
		&.toggled {
			.toggle {
				background-image: url("../../img/icons/arrowRight.svg");
				background-size: 30% 60%;
			}
			.waiting-list-row {
				display: none;
			}
		}
		&:hover {
			box-shadow: rgba(0, 0, 0, 0.35) 0 0 3px 0;
			transition: box-shadow .3s;
			.waiting-list-header {
				text-decoration: none;
			}
		}
	}
}

.latest-dosage-dispensing-organization-tooltip-header {
	font-weight: bold;
}

