.tree-container,
.list-container,
.icon-container{
	i {
		&[class*="-icon"],
		&[class*="file_icon_"],
		&.color-pick {
			width: 22px;
			height: 22px;
			display: inline-block;
			background-size: contain;
			margin: 0 5px 0 0;
			position: relative;
			top: 2px;
			background-repeat: no-repeat;
			&.catalog-package {
				border: 1px solid #FFF;
			}
			&.tree-inactive-icon {
				opacity: .5;
			}
			&.tree-programpathwayreference-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-patientpathwayreference-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-patientpathwayreference-closed-icon {
				background-image: url("../../img/icons/Folder.svg");
				opacity: .5;
			}
			&.tree-sensitivepatientpathwayreference-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-formdefinitionreference-icon {
				background-image: url("../../img/icons/form_placeholder.svg");
			}
			&.tree-formdatareference-icon, &.tree-formdatav2reference-icon, &.tree-formdata-icon, &.tree-formgroup-icon {
				background-image: url("../../img/icons/form.svg");
			}
			&.tree-dynamictemplategroup-icon, &.tree-dynamictemplatepermission-icon {
			  background-image: url("../../img/icons/form.svg");
			}
			&.tree-formdata-warning-icon {
				background-image: url("../../img/icons/Danger.svg");
			}
			&.tree-formdatareference-deleted-icon, &.tree-formdatav2reference-deleted-icon, &.tree-formdata-deleted-icon {
				background-image: url("../../img/icons/form_deleted_fill.svg");
			}
		  &.tree-formdatareference-final-icon, &.tree-formdatav2reference-final-icon, &.tree-formdata-final-icon {
			background-image: url("../../img/icons/form_locked_fill.svg");
		  }
			&.tree-contactreference-icon {
				background-image: url("../../img/icons/Male.svg");
			}
			&.tree-parameterizedpdfletterreference-icon {
				background-image: url("../../img/icons/Mail-3.svg");
				opacity: .5;
			}
			&.tree-pdfletterreference-icon {
				background-image: url("../../img/icons/Mail-3.svg");
			}
			&.tree-pdfletterreference-delete-icon {
				background-image: url("../../img/icons/icon-delete_letter.png");
			}
			&.tree-pathwaydistributionreference-icon {
				background-image: url("../../img/icons/Three-ArrowFork.svg");
			}
			&.tree-parameterizedbasketreference-icon {
				background-image: url("../../img/icons/Folder.svg");
				opacity: .5;
			}
			&.tree-professionalreference-icon {
				background-image: url("../../img/icons/Male.svg");
			}
			&.tree-basketreference-icon, &.tree-basketgroup-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-group-icon, &.tree-document-icon {
				background-image: url("../../img/icons/stop--2-transparent.svg");
			}
			&.tree-measurement-instruction-icon {
				background-image: url("../../img/icons/Pulse.svg");
			}
			&.tree-ordergrantreference-icon {
				width: 22px;
				height: 22px;
				border: 1px solid #000;
			}
			&.tree-basketgrantreference-icon {
				width: 22px;
				height: 22px;
				border: 1px solid #FFF
			}
			&.tree-grantpackagereference-icon {
				width: 19px;
				height: 19px;
				margin: 0 5px 3px 3px;
				&:after {
					width: inherit;
					height: inherit;
					position: absolute;
					top: 2px;
					right: 2px;
					border: inherit;
					background-color: inherit;
					content: '';
				}
			}
			&.tree-basketgrantreference-nocolor-icon {
				background-image: url("../../img/icons/stop--2.svg");
			}
			&.tree-eventreference-icon {
				background-image: url("../../img/icons/Calendar.svg");
			}
			&.tree-parameterizedeventreference-icon {
				background-image: url("../../img/icons/Calendar.svg");
				opacity: .5;
			}
			&.tree-rulegroupreference-icon {
				background-image: url("../../img/icons/Transform.svg");
			}
			&.tree-associationgroupreference-icon {
				background-image: url("../../img/icons/File-ClipboardFileText.svg");
				opacity: .5;
			}
			&.tree-patient-associationgroupreference-icon {
				background-image: url("../../img/icons/File-ClipboardFileText.svg");
			}
		 	&.tree-pathwayplaceholderreferenceresource-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-associationgroupreference-deleted-icon {
				background-image: url("../../img/icons/icon-delete_action_plan.png");
			}
			&.tree-medicationreference-icon,
			&.tree-parameterizedseimessagereference-icon {
				background-image: url("../../img/icons/Receipt-3.svg");
			}
			&.tree-parameterizedseimessagereference-icon {
				opacity: .5;
			}
			&.tree-messagereference-inventorymessage-icon {
				background-image: url("../../img/icons/First-Aid-grid.svg");
			}
			&.tree-messagereference-advis-icon {
				background-image: url("../../img/icons/Envelope-2.svg");
			}
			&.tree-messagereference-letter-icon {
			  background-image: url("../../img/icons/Mail-3.svg");
			}
			&.tree-messagereference-document_message-icon {
			  background-image: url("../../img/icons/Mail-3.svg");
			}
			&.tree-messagereference-lightweight_message-icon {
				background-image: url("../../img/icons/Mail-3.svg");
			}
			&.tree-messagereference-sei-icon {
				background-image: url("../../img/icons/Receipt-3.svg");
			}
			&.tree-messagereference-comment-icon {
				background-image: url("../../img/icons/Paper.svg");
			}
			&.tree-messagereference-medcom-icon {
				background-image: url("../../img/icons/Envelope-2.svg");
			}
			&.tree-messagereference-fmkmessage-icon {
				background-image: url("../../img/icons/Medicine-3.svg");
			}
			&.tree-messagereference-medicationfailedmessage-icon {
				background-image: url("../../img/icons/Medicine-3.svg");
			}
			&.tree-messagereference-smsmessage-icon {
				background-image: url("../../img/icons/Envelope-2.svg");
			}
			&.tree-localemailmessagereference-icon {
				background-image: url("../../img/icons/Mail-withAtSign.svg");
			}
			&.tree-localemailmessagereference-deleted-icon {
				background-image: url("../../img/icons/icon-delete_mail.png");
			}
			&.tree-localsystemmessagereference-icon {
				background-image: url("../../img/icons/Mail-Settings.svg");
				top: 5px;
			}

			&.tree-orderreference-icon, &.tree-ordergroup-icon {
				background-image: url("../../img/icons/Folder.svg");
			}

			&.tree-documentreference-icon, &.tree-documentgroup-icon {
				background-image: url("../../img/icons/File-TextImage.svg");
			}

			&.tree-fkinvoicehandlinggroup-icon,
			&.tree-financepayouttaggroup-icon,
		  	&.tree-citizenaccountreference-icon,
			&.tree-citizenmanualwithdrawaltransactionpermission-icon,
			&.tree-citizenmanualdeposittransactionpermission-icon,
			&.tree-citizenaccountpermission-icon,
			&.tree-patientincomepermission-icon,
			&.tree-financeinsightpermission-icon,
			&.tree-fiancepayouttaggroup-icon,
			&.tree-administrationratespermission-icon,
			&.tree-citizencompensationpermission-icon {
				background-image: url("../../img/icons/Coins.svg");
			}
			&.tree-homeadjustmentpermission-icon {
				background-image: url("../../img/icons/Home-2.svg");
			}

			/* RBAC - Permissions */
		  	&.tree-administrationgroup-icon,
			&.tree-auditprofessionalinformationgroup-icon,
		  	&.tree-generalfunctionsgroup-icon {
				background-image: url("../../img/icons/Gear-2.svg");
			}
		  	&.tree-downloaddocumentpermission-icon {
				background-image: url("../../img/icons/Download-2.svg");
		  	}

			&.tree-organizationreference-icon {
				background-image: url("../../img/icons/Home-4.svg");
			}
			&.tree-patientcategoryadministrationpermission-icon,
			&.tree-patientcategorygroup-icon,
			&.tree-patientcategorypermission-icon {
				background-image: url("../../img/icons/Mens.svg");
			}
			&.tree-inactivepatientcategorygroup-icon,
			&.tree-inactivepatientcategorypermission-icon {
				background-image: url("../../img/icons/Mens.svg");
				opacity: .5;
			}
			&.tree-eventadministrationpermission-icon,
			&.tree-autoeventplanningpermission-icon,
			&.tree-opusvagtplanintegrationgroup-icon,
			&.tree-opusvagtplanupdateplanpermission-icon,
			&.tree-granteventgroup-icon,
			&.tree-resourceeventgroup-icon,
			&.tree-resourceeventpermission-icon,
			&.tree-calendaradministrationpermission-icon,
			&.tree-administrationresourceeventpermission-icon,
			&.tree-registeredgranteventpermission-icon,
			&.tree-planedgranteventgroup-icon,
			&.tree-planedgranteventtypegroup-icon,
			&.tree-plannedgranteventresourcepermission-icon,
			&.tree-plannedgranteventpermission-icon,
			&.tree-roadtimescalculationpermission-icon,
			&.tree-eventgroup-icon,
			&.tree-eventpermission-icon,
			&.tree-groupmeetingeventgroup-icon,
			&.tree-groupgranteventgroup-icon,
			&.tree-groupgranteventpermission-icon,
			&.tree-physicalresourceeventpermission-icon,
			&.tree-nspappointmentpermission-icon,
			&.tree-nspinternalappointmentpermission-icon,
			&.tree-groupeventsubtypegroup-icon,
			&.tree-groupeventsubtypepermission-icon,
			&.tree-groupeventpermission-icon {
				background-image: url("../../img/icons/Calendar.svg");
			}
			&.tree-inactiveadministrationgroup-icon {
				background-image: url("../../img/icons/Gear-2.svg");
				opacity: .5;
			}
			&.tree-inactiveeventgroup-icon,
			&.tree-inactiveeventpermission-icon,
			&.tree-inactivegranteventgroup-icon,
			&.tree-inactiveresourceeventgroup-icon,
			&.tree-inactiveresourceeventpermission-icon,
			&.tree-inactiveregisteredgranteventpermission-icon,
			&.tree-inactiveplanedgranteventgroup-icon,
			&.tree-inactiveplanedgranteventtypegroup-icon,
			&.tree-inactiveplannedgranteventresourcepermission-icon,
			&.tree-inactiveplannedgranteventpermission-icon {
				background-image: url("../../img/icons/Calendar.svg");
				opacity: .5;
			}
			&.tree-patientpermission-icon,
			&.tree-user-icon {
				background-image: url("../../img/icons/Administrator.svg");
			}
			&.tree-inactiveuser-icon {
				background-image: url("../../img/icons/Administrator.svg");
				opacity: .5;
			}
			&.tree-letteradministrationpermission-icon,
			&.tree-lettergroup-icon,
			&.tree-lettertemplategroup-icon,
			&.tree-lettertemplatepermission-icon,
			&.tree-letterwithouttemplatepermission-icon,
			&.tree-doc2mailpermission-icon,
			&.tree-digitalpostpermission-icon,
			&.tree-digitalpostadministrationpermission-icon{
				background-image: url("../../img/icons/Mail-3.svg");
			}
			&.tree-inactivelettergroup-icon,
			&.tree-inactivelettertemplategroup-icon,
			&.tree-inactivelettertemplatepermission-icon,
			&.tree-inactiveletterwithouttemplatepermission-icon {
				background-image: url("../../img/icons/Mail-3.svg");
				opacity: .5;
			}
			&.tree-professionalpermission-icon {
				background-image: url("../../img/icons/Male.svg");
			}
			&.tree-pathwayadministrationpermission-icon,
			&.tree-pathwaygroup-icon,
			&.tree-pathwaytypegroup-icon,
			&.tree-pathwaypermission-icon,
			&.tree-visitationgroup-icon {
				background-image: url("../../img/icons/Folder.svg");
			}
			&.tree-inactivepathwaygroup-icon,
			&.tree-inactivepathwaytypegroup-icon,
			&.tree-inactivepathwaypermission-icon {
				background-image: url("../../img/icons/Folder.svg");
				opacity: .5;
			}
			&.tree-associationgroup-icon,
			&.tree-associationgrouppermission-icon {
				background-image: url("../../img/icons/File-ClipboardFileText.svg");
			}
			&.tree-inactiveassociationgroup-icon,
			&.tree-inactiveassociationgrouppermission-icon {
				background-image: url("../../img/icons/File-ClipboardFileText.svg");
				opacity: .5;
			}
			&.tree-administrationconfigurationpermission-icon,
			&.tree-deleteframeworkgroup-icon,
			&.tree-permanentdeleteformpermission-icon,
			&.tree-permanentdeletepatientpermission-icon,
			&.tree-permanentdeleteauditpermission-icon {
				background-image: url("../../img/icons/Wrench.svg");
			}
			&.tree-ordergrantpermission-icon,
			&.tree-basketrestrictionpermission-icon,
			&.tree-cataloggranttaggroup-icon,
			&.tree-basketgrantgroup-icon,
			&.tree-basketgrantactionspermission-icon,
			&.tree-basketgrantactionspermission-0-icon,
			&.tree-basketgrantactionspermission-1-icon,
			&.tree-basketgrantactionspermission-2-icon,
			&.tree-basketgrantactionspermission-3-icon,
			&.tree-basketgrantactionspermission-4-icon,
			&.tree-basketgrantactionspermission-5-icon,
			&.tree-basketgrantactionspermission-6-icon,
			&.tree-basketgrantactionspermission-7-icon,
			&.tree-basketgrantactionspermission-8-icon,
			&.tree-basketgrantactionspermission-9-icon,
			&.tree-granttagpermission-icon,
			&.tree-grantnotagpermission-icon,
			&.tree-groupplannedgrantpermission-icon,
			&.tree-groupregisteredgrantpermission-icon,
			&.tree-automatedservicelevelchangepermission-icon,
			&.tree-cataloggrantgroup-icon,
			&.tree-cataloggrantpermission-icon {
				background-image: url("../../img/icons/stop--2.svg");
			}
			&.tree-organizationgroup-icon,
			&.tree-organizationpermission-icon,
			&.tree-pathwayorganizationpermission-icon,
			&.tree-sensitiveorganizationadminpermission-icon,
			&.tree-organizations-icon,
			&.tree-patientprofessionalorganizationpermission-icon,
			&.tree-patientorganizationpermission-icon {
				background-image: url("../../img/icons/Home-4.svg");
			}
			&.tree-inactiveorganizations-icon {
				background-image: url("../../img/icons/Home-4.svg");
				opacity: .5;
			}
			&.tree-eopophpermissiongroup-icon
			&.tree-opheopproblempermission-icon,
			&.tree-opheopactionpermission-icon,
			&.tree-opheopvisitationpermission-icon,
			&.tree-opheopsubgoalpermission-icon,
			&.tree-opheopfollowuppermission-icon,
			&.tree-opheopevaluationpermission-icon,
			&.tree-eopvumpermissiongroup-icon
			&.tree-vumeopproblempermission-icon,
			&.tree-vumeopactionpermission-icon,
			&.tree-vumeopvisitationpermission-icon,
			&.tree-vumeopsubgoalpermission-icon,
			&.tree-vumeopfollowuppermission-icon,
			&.tree-vumeopevaluationpermission-icon,
			&.tree-eopffbpermissiongroup-icon,
			&.tree-ffbeopproblempermission-icon,
			&.tree-ffbeopactionpermission-icon,
			&.tree-ffbeopvisitationpermission-icon,
			&.tree-ffbeopsubgoalpermission-icon,
			&.tree-ffbeopfollowuppermission-icon,
			&.tree-ffbeopevaluationpermission-icon{
				background-image: url("../../img/icons/EopNonIcs.svg");
			}
			&.tree-eopicspermissiongroup-icon,
			&.tree-icseopproblempermission-icon,
			&.tree-icseopactionpermission-icon,
			&.tree-icseopvisitationpermission-icon,
			&.tree-icseopsubgoalpermission-icon,
			&.tree-icseopfollowuppermission-icon,
			&.tree-icseopevaluationpermission-icon {
			  background-image: url("../../img/icons/EopIcs.svg");
			}

			&.tree-eopbnlpermissiongroup-icon {
			    background-image: url("../../img/icons/EopIcs.svg");
			}
			&.tree-bnleopactionpermission-icon,
			&.tree-bnl-eop_action-icon {
				background-image: url("../../img/icons/eop/action.svg");
			}
			&.tree-bnleopevaluationpermission-icon,
			&.tree-bnl-eop_evaluation-icon {
				background-image: url("../../img/icons/eop/evaluation.svg");
			}
			&.tree-bnleopproblempermission-icon,
			&.tree-bnl-eop_problem-icon {
				background-image: url("../../img/icons/eop/focusarea.svg");
			}
			&.tree-bnleopfollowuppermission-icon,
			&.tree-bnl-eop_follow_up-icon {
				background-image: url("../../img/icons/eop/followup.svg");
			}
			&.tree-bnleopsubgoalpermission-icon,
			&.tree-bnl-eop_subgoal-icon {
				background-image: url("../../img/icons/eop/subgoal.svg");
			}
			&.tree-bnleopvisitationpermission-icon,
			&.tree-bnl-eop_visitation-icon {
				background-image: url("../../img/icons/eop/visitation.svg");
			}

			&.tree-oph-eop_action-icon,
			&.tree-oph-eop_evaluation-icon,
			&.tree-oph-eop_problem-icon,
			&.tree-oph-eop_follow_up-icon,
			&.tree-oph-eop_subgoal-icon,
			&.tree-oph-eop_visitation-icon,
			&.tree-ffb-eop_action-icon,
			&.tree-ffb-eop_evaluation-icon,
			&.tree-ffb-eop_problem-icon,
			&.tree-ffb-eop_follow_up-icon,
			&.tree-ffb-eop_subgoal-icon,
			&.tree-ffb-eop_visitation-icon,
			&.tree-vum-eop_action-icon,
			&.tree-vum-eop_evaluation-icon,
			&.tree-vum-eop_problem-icon,
			&.tree-vum-eop_follow_up-icon,
			&.tree-vum-eop_subgoal-icon,
			&.tree-vum-eop_visitation-icon {
				background-image: url("../../img/icons/EopNonIcs.svg");
			}

			&.tree-ics-eop_action-icon,
			&.tree-ics-eop_evaluation-icon,
			&.tree-ics-eop_problem-icon,
			&.tree-ics-eop_follow_up-icon,
			&.tree-ics-eop_subgoal-icon,
			&.tree-ics-eop_visitation-icon {
				background-image: url("../../img/icons/EopIcs.svg");
			}

			&.tree-securitymanagementpermission-icon,
			&.tree-rolegroup-icon,
			&.tree-rolepermission-icon {
				background-image: url("../../img/icons/Password-shopping.svg");
			}
			&.tree-inactiverolegroup-icon,
			&.tree-inactiverolepermission-icon {
				background-image: url("../../img/icons/Password-shopping.svg");
			}
			&.tree-taggroup-icon,
			&.tree-formtaggroup-icon,
			&.tree-formtagpermission-icon,
			&.tree-documenttaggroup-icon,
			&.tree-documenttagpermission-icon,
			&.tree-lettertaggroup-icon,
			&.tree-lettertagpermission-icon,
			&.tree-tagadministrationpermission-icon {
				background-image: url("../../img/icons/Tag.svg");
			}
			&.tree-inactivetaggroup-icon,
			&.tree-inactiveformtaggroup-icon,
			&.tree-inactiveformtagpermission-icon,
			&.tree-inactivedocumenttaggroup-icon,
			&.tree-inactivedocumenttagpermission-icon,
			&.tree-inactivelettertaggroup-icon,
			&.tree-inactivelettertagpermission-icon {
				background-image: url("../../img/icons/Tag.svg");
				opacity: .5;
			}
		  &.tree-externallinksgroup-icon,
		  &.tree-novalinkpermission-icon,
		  &.tree-sundhedsjournallinkpermission-icon,
		  &.tree-samblikdiabeteslinkpermission-icon,
		  &.tree-sapalinkpermission-icon {
			background-image: url("../../img/icons/Link.svg");
		  }
		  &.tree-assignmentsadministrationpermission-icon {
			background-image: url("../../img/icons/Clock.png");
		  }
			&.tree-organizationcontact-icon {
				background-image: url("../../img/icons/Clinic.svg");
			}
			&.tree-professionalcontact-icon {
				background-image: url("../../img/icons/Doctor.svg");
			}
			&.tree-relativecontact-icon {
				background-image: url("../../img/icons/Family-Sign.svg");
			}
			&.tree-citizenrelativecontact-icon {
				background-image: url("../../img/icons/Couple-Sign.svg");
			}
			&.tree-patientnetworkcontact-icon {
				background-image: url("../../img/icons/Conference.svg");
			}

			&.tree-messagegroup-icon,
			&.tree-patientadvispermission-icon,
			&.tree-professionaladvispermission-icon,
			&.tree-medcomgroup-icon,
			&.tree-medcomcorrespondencepermission-icon,
			&.tree-medcomreceiptspermission-icon,
			&.tree-seioutboxpermission-icon,
			&.tree-seipermission-icon,
			&.tree-dischargeletterpermission-icon,
			&.tree-dgoppermission-icon,
			&.tree-hospitalreferralpermission-icon,
			&.tree-municipalityreferralpermission-icon,
			&.tree-municipalityletterpermission-icon,
			&.tree-hospitalnotificationpermission-icon,
			&.tree-birthnotificationpermission-icon,
			&.tree-emergencymunicipalityreferralpermission-icon,
			&.tree-emergencymunicipalityletterpermission-icon,
			&.tree-fs3dataexchangesetpermission-icon,
			&.tree-notificationofadmissionpermission-icon,
			&.tree-reportofadmissionpermission-icon,
			&.tree-warningofdischargepermission-icon,
			&.tree-progressofcareplanpermission-icon,
			&.tree-medcomprescriptionrenewalpermission-icon,
			&.tree-notificationofdischargepermission-icon,
			&.tree-reportofdischargepermission-icon {
				background-image: url("../../img/icons/Envelope-2.svg");
			}
			&.tree-medicationgroup-icon,

			&.tree-dispensinggroup-icon,
			&.tree-medicinedispensingmeasurepermission-icon,
			&.tree-medicinedispensingcontrolpermission-icon,
			&.tree-margroup-icon,
			&.tree-marstatusgivenpermission-icon,
			&.tree-marstatusnotgivenpermission-icon,
			&.tree-marstatushandedoverpermission-icon,
			&.tree-marstatusnotingestedpermission-icon,
			&.tree-fmkgroup-icon,
			&.tree-fmkprescriptionrenewalpermission-icon,
			&.tree-fmkupdatepermission-icon,
			&.tree-fmkonbehalfpermission-ico,
			&.tree-fmkconnectpermission-icon,
			&.tree-fmkremoveallorganizationspermission-icon,
			&.tree-fmkoverrideconsentpermission-icon,
			&.tree-privatemedicationpermission-icon,
			&.tree-fmktrustpermission-icon,
			&.tree-fmkonbehalfpermission-icon,
			&.tree-pnmedicinegroup-icon,
			&.tree-effectuationgroup-icon,
			&.tree-medicationdispensationmeasuredpermission-icon,
			&.tree-medicationdispensationglobalpermission-icon,
			&.tree-medicationdispensationgivenpermission-icon,
			&.tree-medicineadministrationpermission-icon,
			&.tree-medicationcardpermission-icon,
			&.tree-medicationcardnursingpermission-icon,
			&.tree-medicinecooperationworkflowpermission-icon,
			&.tree-effectuationwithrepetitionworkflowpermission-icon,
			&.tree-effectuationwithrepetitionhandoutpermission-icon,
			&.tree-effectuationwithdistributionworkflowpermission-icon,
			&.tree-medicationinventorypermission-icon,
			&.tree-effectuationwithrepetitionpermission-icon,
			&.tree-effectuationlabelprintpermission-icon,
			&.tree-effectuationpatternpermission-icon,
			&.tree-medicationpermission-icon {
				background-image: url("../../img/icons/Medicine-3.svg");
			}
			&.tree-rulesgroup-icon,
			&.tree-nabpermission-icon,
			&.tree-smdbpermission-icon {
				background-image: url("../../img/icons/Transform.svg");
			}
			&.tree-reportgroup-icon,
			&.tree-reportpermission-icon {
				background-image: url("../../img/icons/Line-Chart.svg");
			}
			&.tree-formgroup-icon,
			&.tree-formdefinitionpermission-icon,
			&.tree-formdefinitionadministrationpermission-icon {
				background-image: url("../../img/icons/form.svg");
			}
			&.tree-inactiveformdefinitionpermission-icon {
				background-image: url("../../img/icons/form.svg");
				opacity: .5;
			}

			&.tree-preferencegroup-icon,
			&.tree-preferencepermission-icon {
				background-image: url("../../img/icons/Filter-2.svg");
			}
		  	&.tree-preferenceadminpermission-icon {
				background-image: url("../../img/icons/Filter-2.svg");
		  	}
			&.tree-inactivepathwaygroup-icon,
			&.tree-inactivepathwaytypegroup-icon {
				background-image: url("../../img/icons/Folder.svg");
				opacity: .5;
			}
			&.tree-inactiveformgroup-icon {
				background-image: url("../../img/icons/form.svg");
				opacity: .5;
			}
			&.tree-inactiveresourceeventgroup-icon {
				background-image: url("../../img/icons/Calendar.svg");
				opacity: .5;
			}
			&.tree-temp-icon {
				background-image: url("../../img/icons/Depression.svg");
			}
			&.tree-physicalresourcegroup-icon,
			&.tree-physicalresourcepermission-icon,
			&.tree-physicalresourceadministrationpermission-icon {
				background-image: url("../../img/icons/Sand-watch.svg");
			}
			&.tree-mobilityaidgroup-icon,
			&.tree-hmsmobilityaidspermission-icon,
			&.tree-hmsmobilityaidsdmsorderingpermission-icon,
			&.tree-hclorderslendingsgroup-icon,
			&.tree-hclorderslendingspermission-icon,
			&.tree-hcldepotgroup-icon,
			&.tree-hclmobilelookuppermission-icon,
			&.tree-hcladministrationpermission-icon,
			&.tree-hcldepotorderpermission-icon,
			&.tree-hcldepotrepairpermission-icon,
			&.tree-lending-icon,
			&.tree-hclhmicatalogadministrationpermission-icon,
			&.tree-hclminimuminventoryadministrationpermission-icon,
			&.tree-hclvisitationdepotaccessgroup-icon,
			&.tree-hclvisitationdepotaccesspermission-icon,
			&.tree-hcladministrationgroup-icon,
			&.tree-hclowncatalogadministrationpermission-icon,
			&.tree-hclautomationadministrationpermission-icon,
			&.tree-hclvisitationproducttagaccessgroup-icon,
			&.tree-hclvisitationproducttagaccesspermission-icon,
			&.tree-hclvisitationproductwithouttagaccesspermission-icon,
			&.tree-hclvisitationallproducttagsaccesspermission-icon,
			&.tree-hclvisitationlendingdiscardpermission-icon {
				background-image: url("../../img/icons/Wheelchair.svg");
			}
			&.tree-importcprrelativespermission-icon,
			&.tree-importcprspousespermission-icon,
			&.tree-relativecontactpermission-icon{
			  background-image: url("../../img/icons/Family-Sign.svg");
			}
			&.tree-patientnetworkcontactpermission-icon{
			  background-image: url("../../img/icons/Conference.svg");
			}
			&.tree-contactgroup-icon,
			&.tree-sorcontactpermission-icon,
			&.tree-patientdatapermissiongroup-icon,
			&.tree-schoolinformationpermission-icon,
			&.tree-patientreimbursementinformationpermission-icon,
			&.tree-organdonationpermission-icon,
			&.tree-lifetreatmentwillpermission-icon,
			&.tree-temporaryaddresspermission-icon,
			&.tree-healthinsurancegrouppermission-icon,
			&.tree-preferredlanguagepermission-icon,
			&.tree-patientstatepermission-icon,
			&.tree-phonenumberpermission-icon,
			&.tree-assigncprpermission-icon,
			&.tree-patientdataauditprofessionalinformationpermission-icon{
				background-image: url("../../img/icons/Administrator.svg");
			}
		  	&.tree-assignmentgroup-icon,
			&.tree-assignmenttypesgroup-icon,
			&.tree-assignmentsbulkreassignpermission-icon,
		  	&.tree-assignmentpermission-icon {
				background-image: url("../../img/icons/Clock.png");
		  	}
			&.tree-fkinvoicehandlingactionpermission-icon,
			&.tree-financepayoutstransactionspermission-icon,
			&.tree-payoutsinformationpermission-icon,
			&.tree-financialtransactiongroup-icon,
			&.tree-financialtransactionpermission-icon,
			&.tree-financialtransactionadministrationpermission-icon,
			&.tree-payoutrecipientadministrationpermission-icon,
			&.tree-basketgrantactionspermission-10-icon,
			&.tree-basketgrantactionspermission-11-icon,
			&.tree-financialtransactionnetsagreementpermission-icon {
				background-image: url("../../img/icons/Financial.svg");
			}
			&.tree-citizenaccountgroup-icon {
			  background-image: url("../../img/icons/Wallet-2.svg");
			}
			&.tree-logviewerpermission-icon {
				background-image: url("../../img/icons/Optimization.svg");
			}
			&.tree-emailgroup-icon,
			&.tree-emailpermission-icon {
				background-image: url("../../img/icons/Mail-withAtSign.svg");
			}
			&.tree-role-icon {
				background-image: url("../../img/icons/Profile.svg");
				+ a {
					position: relative;
					top: -5px;
				}
			}
		  &.tree-assignment-deadline-green-icon {
				background-image: url("../../img/icons/icon_flag_green.svg");
				width: 15px;
				vertical-align: bottom;
		  	}
		  &.tree-assignment-deadline-yellow-icon {
				background-image: url("../../img/icons/icon_flag_yellow.svg");
				width: 15px;
				vertical-align: bottom;
		  	}
		  &.tree-assignment-deadline-gray-icon {
				background-image: url("../../img/icons/icon_flag_gray.svg");
				width: 15px;
				vertical-align: bottom;
			}
		  &.tree-assignment-deadline-red-icon {
				background-image: url("../../img/icons/icon_flag_red.svg");
				width: 15px;
				vertical-align: bottom;
		  	}
			&.tree-conditiongroup-icon {
				background-image: url("../../img/icons/Record.svg");
			}
			&.tree-servicelawpatientconditionpermission-icon {
				background-image: url("../../img/icons/ServiceLaw.svg");
			}
			&.tree-healthlawpatientconditionpermission-icon {
				background-image: url("../../img/icons/HealthLaw.svg");
			}
		    &.tree-locallawpatientconditionpermission-icon {
			    background-image: url("../../img/icons/LocalLaw.svg");
		    }
			&.tree-traininglawpatientconditionpermission-icon {
			  background-image: url("../../img/icons/TrainingConditionsLaw.svg");
			}
			&.tree-healthpromotionslawpatientconditionpermission-icon {
			  background-image: url("../../img/icons/HealthPromotionsConditionsLaw.svg");
			}
		    &.tree-nursingproblemareaslawpatientconditionpermission-icon {
			  background-image: url("../../img/icons/NursingProblemAreasLaw.svg");
		    }
			&.tree-measurementpermission-icon,
			&.tree-measurementgroup-icon,
			&.tree-measurementadministrationpermission-icon {
				background-image: url("../../img/icons/Pulse.svg");
			}
			&.tree-inactivemeasurementpermission-icon,
			&.tree-inactivemeasurementgroup-icon {
				background-image: url("../../img/icons/Pulse.svg");
			  	opacity: .5;
		  	}
		  	&.tree-role-inactive-icon {
				background-image: url("../../img/icons/Profile.svg");
				opacity: .5;
				+ a {
					position: relative;
					top: -5px;
				}
			}
			&.tree-outgoingmessagesgroup-icon,
			&.tree-outgoingmessagespermission-icon {
				background-image: url("../../img/icons/message.svg");
			  	opacity: .5;
		  	}
			&.tree-medicationdispensation-icon {
			  background-image: url("../../img/icons/Medicine-3.svg");
			}
		  	&.tree-basicreportingpermission-icon,
		    &.tree-basicreportinggroup-icon,
		    &.tree-reportingseeallcitizenspermission-icon,
		    &.tree-reportingseemycitizenspermission-icon,
		    &.tree-reportingseenonsensitivepathwaypermission-icon,
		    &.tree-reportingseesensitivepathwaypermission-icon,
		    &.tree-reportingseemedicinepermission-icon,
		    &.tree-reportingseehclpermission-icon {
			  background-image: url("../../img/icons/Bar-Chart.svg");
		  	}
			&.tree-videogroup-icon,
			&.tree-assistedloginvideopermission-icon,
			&.tree-startcallvideopermission-icon {
			  background-image: url("../../img/icons/video-camera.svg");
			}
			&.tree-distributionwaitinglistgroup-icon,
			&.tree-distributionwaitinglistpermission-icon {
			  background-image: url("../../img/icons/distribution-waiting-list.svg");
			}
			+ div {
				position: relative;
				top: -4px;
			}
			/*
				if you want to check if you have all then there is class PermissionTreeFactory that is used to build the tree. And there is a list called "subTreeFactories"
				for each group there is one subTreeFactory
				inside subTreeFactory you can find the class that extends PermissionActivityInterface
				it is a class for group
				so the type on node should be the name of that class
				and there is a list of suppoortedTypes by subTreeFactory
				there you can find the names of permission inside this group
				if you need something else then you can try check it there or just let me know what you need
			*/
		}
	}
}
