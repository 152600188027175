nx-flow-graph {
  flex: 1;
  display: block;
  width: 100%;
}

.flow-container {
  padding: 6px 3px;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column
  display: -ms-flexbox;
  -ms-flex-direction: column;
}
.flow-container * {
  color: #333333;
}

.flow-panel-wrapper.ng-leave {
  transition: right .3s, opacity .3s;
}

.flow-panel {
  width: 75%;
  height: 100%;
  position: absolute;
  right: -75%;
  top: 0;
  z-index: 1;
  opacity: 0;
  -webkit-transition: right .3s, opacity .3s;
  transition: right .3s, opacity .3s;
}
.flow-panel .flow-panel-inner {
  height: 100%;
  background: #fff;
  margin: 0 0 0 30px;
  padding: 0 25px;
  overflow: auto;
}

.flow-row .flow-column .flow-element .node .node-percentage {
  display: none;
}

@media screen and (min-width: 1200px) {
  .flow-panel {
	width: 50%;
	right: -50%;
  }
}
.flow-panel .header-container {
  margin: 10px 0 0 0;
  padding: 5px 75px 5px 10px;
  border: 1px solid #eeeeee;
  height: 62px;
  position: relative;
}
.flow-panel .header-container h2 {
  font-size: 14px;
  word-break: break-all;
  height: 55px;
  vertical-align: middle;
  display: table-cell;
  padding: 0 0 0 10px;
  font-weight: bold;
}
.flow-panel .header-container .percentage {
  background: #0d75ae;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 50px;
  width: 60px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding: 16px 0;
}
.flow-panel .header-container .percentage.done {
  background: #a8a8a8 url("../img/check_large.png") center center no-repeat;
}
.flow-panel table {
  margin: 0 0 5px 15px;
}
.flow-panel table td {
  padding: 0 12px 0 0;
  font-size: 12px;
}
.flow-panel table td h3 {
  margin: 30px 0 20px 0;
  font-size: 14px;
  font-weight: bold;
}
.flow-panel table td h3 span {
  display: block;
  background: #fff;
  width: 150px;
  position: relative;
}
.flow-panel table td ul {
  padding-left: 20px;
}
.flow-panel table td ul li {
  font-size: 12px;
  line-height: 18px;
}
.flow-panel table td.last-child {
  width: 66px;
  text-align: center;
  font-size: 14px;
  padding: 0 6px 0 0;
  vertical-align: top;
}

.flow-panel table td.last-child.done {
  background: transparent url("../img/check_medium.png") center center no-repeat;
}
.flow-panel .flow-toggle {
  width: 30px;
  height: 32px;
  border-right: 0;
  background: #f5f5f5 url("../img/chevron_right.png") center center no-repeat;
  position: absolute;
  left: 0;
  top: -1px;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  height: 100%;
  border-bottom: 1px solid #b8b8b8;
  border-left: 1px solid #b8b8b8;
  border-top: 1px solid #b8b8b8;
}
.flow-panel.shown, .flow-panel.ng-enter.ng-enter-active {
  right: 0;
  opacity: 1;
  -webkit-transition: right .3s, opacity .3s;
  transition: right .3s, opacity .3s;
}

.flow-panel.ng-leave {
  transition: right .3s, opacity .3s;
}

.flow-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 12px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

}
.flow-row .flow-column {
  background: #e7e7e7;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
}

.flow-row .flow-column-wrapper {
  width:100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex:1;
  -ms-flex: 1;
}

.flow-inner-column-wrapper {
  display flex;
  display: -ms-flexbox;
}

.flow-inner-column {
  flex:1;
  -ms-flex: 1;
}

.flow-row .flow-column .flow-element {
  width: 100%;
  background: #e7e7e7;
  position: relative;
}

nx-node, .flex-container {
  /*  border-left: 3px solid white;
  border-right: 3px solid white;*/
}

.flow-row .flow-column .flow-element.no-content {
  height: 105px;
}
.flow-row .flow-column .flow-element.no-content.no-content-top {
  height: 105px;
}
.flow-row .flow-column .flow-element.no-content.no-content-btm {
  height: 105px;
}
.flow-row .flow-column .flow-element.no-content.no-content-btm .node-container-bar.separator {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.vertical-column-separator {
  width: 6px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.flow-row .flow-column .flow-element.no-content .node-container-bar.bottom-aligned {
  padding: 53px 0 0 0;
}
.flow-row .flow-column .flow-element.light {
  background: #f5f5f5;
}
.flow-row .flow-column .flow-element .arrow-out-left,
.flow-row .flow-column .flow-element .arrow-in-left {
  width: 50%;
  height: 1px;
  background: #333;
  position: absolute;
  right: 0;
  top: 50%;
}
.flow-row .flow-column .flow-element .arrow-out-right,
.flow-row .flow-column .flow-element .arrow-in-right {
  width: 50%;
  height: 1px;
  background: #333;
  position: absolute;
  right: 50%;
  top: 50%;
}
.flow-row .flow-column .flow-element .arrow-both-full {
  width: 100%;
  height: 1px;
  background: #333;
  position: absolute;
  right: 0;
  top: 50%;
}
.flow-row .flow-column .flow-element .arrow-right-in,
.flow-row .flow-column .flow-element .arrow-right-out {
  width: 10%;
  height: 1px;
  background: #333;
  position: absolute;
  right: -6px;
  top: 50%;
}
.flow-row .flow-column .flow-element .arrow-right-in .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-right-in .arrow-head-btm,
.flow-row .flow-column .flow-element .arrow-right-out .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-right-out .arrow-head-btm {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  border-right: 3px solid #333;
  border-left: 3px solid transparent;
}
.flow-row .flow-column .flow-element .arrow-right-in .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-right-out .arrow-head-top {
  border-top: 3px solid transparent;
  border-bottom: 3px solid #333;
  bottom: 1px;
}
.flow-row .flow-column .flow-element .arrow-right-in .arrow-head-btm,
.flow-row .flow-column .flow-element .arrow-right-out .arrow-head-btm {
  border-top: 3px solid #333;
  border-bottom: 3px solid transparent;
  top: 1px;
}
.flow-row .flow-column .flow-element .arrow-left-in,
.flow-row .flow-column .flow-element .arrow-left-out {
  width: 10%;
  height: 1px;
  background: #333;
  position: absolute;
  left: -6px;
  top: 50%;
}
.flow-row .flow-column .flow-element .arrow-left-in .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-left-in .arrow-head-btm,
.flow-row .flow-column .flow-element .arrow-left-out .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-left-out .arrow-head-btm {
  width: 0;
  height: 0;
  position: absolute;
  right: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid #333;
}
.flow-row .flow-column .flow-element .arrow-left-in .arrow-head-top,
.flow-row .flow-column .flow-element .arrow-left-out .arrow-head-top {
  border-top: 3px solid transparent;
  border-bottom: 3px solid #333;
  bottom: 0;
}
.flow-row .flow-column .flow-element .arrow-left-in .arrow-head-btm,
.flow-row .flow-column .flow-element .arrow-left-out .arrow-head-btm {
  border-top: 3px solid #333;
  border-bottom: 3px solid transparent;
  top: 0;
}
.flow-row .flow-column .flow-element .node {
  margin: 0 auto;
  width: 90%;
  max-width: 200px;
  min-width:120px;
  min-height: 65px;
  cursor: pointer;
  overflow: hidden;
}
.flow-row .flow-column .flow-element .node .node-inner {
  background: #fff;
  margin: 5px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.flow-row .flow-column .flow-element .node .node-label {
  word-break: break-all;
  height: 55px;
  vertical-align: middle;
  display: table-cell;
  padding: 0 5px;
  font-size: 12px;
}
.flow-row .flow-column .flow-element .node .node-percentage {
  background: #0d75ae;
  position: absolute;
  right: 10px;
  top: 13px;
  height: 28px;
  width: 29px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  padding: 7px 0px 15px;
  font-weight: bold;
  display: none;
}
.flow-row .flow-column .flow-element .node.done .node-inner {
  background: #a8a8a8;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.flow-row .flow-column .flow-element .node.done .node-percentage {
  background: white url("../img/check_small.png") center center no-repeat;
}
.flow-row .flow-column .flow-element .node.active {
  background: #0d75ae;
  border-radius: 6px;
}
@media (min-width: 1025px) {
  .flow-row .flow-column .flow-element .node .node-label {
	padding-right: 50px;
  }
  .flow-row .flow-column .flow-element .node .node-percentage {
	display: block;
  }
}
.flow-row .flow-column .flow-element .node-container-bar {
  height: 20px;
  position: relative;
}
.flow-row .flow-column .flow-element .node-container-bar.large {
  height: 230px;
}
.flow-row .flow-column .node-container-bar.separator {
  height: 6px;
  background: #fff;
}
.flow-row .flow-column .flow-element .node-container-bar .arrow-head-left,
.flow-row .flow-column .flow-element .node-container-bar .arrow-head-right {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 3px solid #333;
  border-bottom: 3px solid transparent;
}
.flow-row .flow-column .flow-element .node-container-bar .arrow-head-left {
  right: 50%;
  position: absolute;
  border-right: 3px solid #333;
  border-left: 3px solid transparent;
}
.flow-row .flow-column .flow-element .node-container-bar .arrow-head-right {
  left: 50%;
  border-right: 3px solid transparent;
  border-left: 3px solid #333;
}
.flow-row .flow-column .node-container-bar .arrow {
  width: 1px;
  height: inherit;
  background-color: #333;
  margin: auto;
  height: 100%;
}

.flex-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  flex: 1;
  -ms-flex: 1;
  align-items: center;
  -ms-flex-align: center;

  .flex-arrow {
	width: 1px;
	flex: 1;
	-ms-flex: 1;
	background: black;
  }
}



.flow-row .flow-column .arrow-horizontal {
  height: 1px;
  background: #333;
  width: 100%;
}
.flow-row .flow-column .arrow-horizontal.arrow-left, .flow-row .flow-column .arrow-horizontal.arrow-right {
  width: 50%;
}
.flow-row .flow-column .arrow-horizontal.arrow-left {
  margin: 0 50% 0 0;
}
.flow-row .flow-column .arrow-horizontal.arrow-right {
  margin: 0 0 0 50%;
}
.flow-row.arrow-small .flow-column {
  border-right: 3px solid #fff;
  border-left: 3px solid #fff;
}
.flow-row.arrow-small .flow-column.column-arrow-right {
  border-right: 3px solid #333;
}
.flow-row.arrow-small .flow-column.column-arrow-left {
  border-left: 3px solid #333;
}
.flow-row.arrow-large .flow-column {
  border-right: 3px solid #f5f5f5;
  border-left: 3px solid #f5f5f5;
}
.flow-row.arrow-large .flow-column.first-column {
  border-left: 3px solid #fff;
}
.flow-row.arrow-large .flow-column.last-column {
  border-right: 3px solid #fff;
}
.flow-row.arrow-small .flow-column.column-arrow-both, .flow-row.arrow-large .flow-column.column-arrow-both {
  border-right: 3px solid #333;
  border-left: 3px solid #333;
}
