$panel-border-color = #aaa

.association-group-page {
	width: 100%;
	padding-bottom: 10px;
	&.mobile {
		> :not([problem-panel]) {
			.form-group {

			  &.required {
				.control-label {
				  padding-left: 10px;
				}
			  }
			  .control-label {
				padding-left: 2px;
				width:100%;
			  }
			  > div {
				&[class="col-sm-2"] {
				  width: 50%;
				  display: inline-block;
				}
				&[class="col-sm-8"] {
				  width: 90%;
				  display: inline-block;
				}
			  }
			}
		}
	}

	.expand-button {
		float: leftt;
		background: transparent;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		border: 0;
		padding: 0;
		vertical-align: top;
	}
	.panel-collapsed {
		.expand-button {
			background-image: url("../img/icons/arrowRight.svg");
			background-position: 5px 0;
		}
		.panel-heading {
			border-bottom: 0;
		}
		.panel-body, .panel-form {
			display: none;
		}
	}
	.panel-expanded .expand-button {
		background-image: url("../img/icons/arrowDown.svg");
		background-position: 0 5px;
	}

	.panel-container {
		padding: 10px 10px 0 10px;

		.panel {
			border-color: $panel-border-color;
			.alert {
				margin: auto;
				padding: auto;
			}
		}

		.panel-heading {
			padding-top: 20px;
			padding-bottom: 15px;
			vertical-align: middle;
			border-color: #ddd;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			cursor: pointer;
			.panel-title {
				padding-left: 5px;
				font-size: 14px;
				font-weight: bold;
			}
			.panel-date {
				float: right;
			}
			.panel-title,
			.panel-date {
				color: #000;
			}
			.panel-description-root {
				color: #666;
				padding-left: 5px;
				&:before {
					padding: 10px;
				}
			}
			.panel-description {
				color: #666;
				display: block;
				padding-top: 5px;
				margin-left: 28px;
			}
			.panel-tag {
				display: block;
				padding-top: 5px;
				margin-left: 28px;
				span {
					display: inline-block;
					margin: 0 5px 5px 0px;
					border: 1px solid #cccccc;
					padding: 2px 5px;
					border-radius: 3px;
					color: $default-text-color;
					background: #e6e6e6;
					position: relative;
				 	op: 0;
					font-weight: normal;
				}
			}
		}
		.panel-removed {
			.panel-heading * {
				color: #ccc;
				text-decoration: line-through;
			}
		}
		.panel-canceled {
			.panel-heading * {
				color: #ccc;
			}
		}

		.panel-completed {
			.panel-heading * {
				color: #ccc;
			}
		}

		.panel-body {
			padding: 0;
		}

		.panel-buttons .btn-group {
			padding-right: 10px;
		}
		.row .row {
			padding: 10px;
		}

		.row-label {
			padding: 7px 10px 0 0;
		}
		.panel-divider {
			border-bottom: 1px solid #ddd;
		}

		.panel-plan {
			.panel-form {
				border-right: 1px solid #ddd;
			}
			.audit {
				border: none !important;
			}
		}
	}
	.divider {
		width: 100%;
		border-bottom: 1px solid #ccc;
		padding-top: 0;
		margin: 10px 10px 0 10px !important;
	}
	.see-more-tasks {
		padding: 10px;
		display: inline-block;
	}
    .audit-container {
	  border: 1px solid #ccc;
	  overflow-y: auto;
	}
	.audit {
		.adt-log {
			margin: 0 !important;
			> li {
				padding-left: 0 !important;
			}
		}
	}
	@media (max-width $screen-md-min) {
		.col-panels {
			padding-left: 40px;
		}
		/*.panel-plan {
		  .panel-form {
			border-right: none;
			border-bottom: 1px solid #ddd;
		  }
		}*/
		.divider {
			display: none;
		}
	}
	.button-placeholder {
		height: 37px;
		margin-top: 10px;
	}

	.ui-select-bootstrap {
	  .ui-select-choices-row-inner {
		> div {
		  text-overflow: ellipsis;
		  overflow: hidden;
		}
	  }
	  .ui-select-match-text {
		span {
		  display: inherit;
		  text-overflow: ellipsis;
		}
	  }
	}
}
