.audit-container {
  border-top: 2px solid #c9c9c9;
}
@media (min-width: $screen-md-min) {
  .audit-container {
	border-top: 0;
	border-left: 2px solid #c9c9c9;
  }
}
.audit-header {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
}
.audit {
	ul {
		padding-left: 0;
		list-style: none;
	}
	ul.adt-log {
		line-height: 24px;
		margin-bottom: 20px;
		> li {
			padding: 10px 10px;
			.remove-button {
				visibility: hidden;
			}
			&:hover {
				.remove-button {
					visibility: visible;
				}

			}
		}
		h5 {
			margin: 0;
			.adt-action {
				font-weight: bold;
			}
		}
		ul {
			span {
				background-image: url("../img/icons/chevron-right.svg");
				background-repeat: no-repeat;
				background-size: 13px 13px;
				background-position: left center;
				padding-left: 15px;
				&.no-change:before {
					content: "";
					opacity: .8;
					background-color: #fff;
					width: 14px;
					height: 20px;
					position: absolute;
					display: inline-block;
					margin-left: -15px;
				}
			}
		  	span.clean {
		  		padding: 0px;
			  	background-image: none;
		  	}
		}
	}
	.form-group {
		padding: 5px 10px;
	}
	.adt-toggle {
		width: 300px;
	}
	.adt-toggle-3 {
		width: 450px;
	}
	.removed-note {
		text-decoration: line-through;
		color: #9e9e9e;
	}
	.remove-info {
		font-size: 12px;
		color: #9e9e9e;
	}
	.indent-note {
		padding-left: 20px;
	}
}

@media (min-width: $screen-md-min) {
	.list {
		border-right: 1px solid #c9c9c9;
		.list-content {
			a {
				position: relative;
				top: -3px;
			}
		}
	}
	.table.table-mobile-optimized > tbody tr td table.related-message-table > tbody tr td:first-child {
	  width: 80%;
	}
	.table.table-mobile-optimized > tbody tr td table.related-message-table > tbody tr td:last-child {
	  width: 20%;
	}
	.table.table-click > tbody tr td.disabled table.related-message-table > tbody tr td {
	  background: $item-selected-color;
	  cursor: context-menu;
	}
}
