@require "bootstrap/variables";

.dashboard {
	height: auto;
	width: 100%;
	padding: 20px 0 20px 20px;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;

	.dashboard-column {
		margin-right: 20px;
		.dashboard-column-item {
			width: 100%;
			margin-bottom: 20px;
		  .measurement-widgets {
			border-left: none;
			border-top : none;
		  }
		}
	}
}

td.col-hours {
	padding-right: 0px !important;
}

.master-filters {
  display: inline-block;
  vertical-align: top;
  .data-toolbar {
	background: none;
	padding: 0px;
  }
}

.dashboard-alert-container {
  width: 100%;
}

.widget .subgoal-widget-content .problem-subgoal-widget-content div.subgoal-overview {
  padding-left: 5px;
}

.widget .problem-widget-content .problem-subgoal-widget-content div.subgoal-overview {
  padding-left: 80px;
}

@media (min-width: $screen-md-min) {
	.dashboard {
		flex-direction: row;

		.dashboard-column {
			flex: 1;
			-ms-flex: 1;
		  	min-width: 100px;
		}
	}
}

.widget {
	border: 1px solid #b0b0b0;
	.server-error {
		margin: 10px;
	}

	.widget-header {
		padding: 3px 9px 3px 11px;
		background-color: #addfe9;
		border: 0;
		border-bottom: 1px solid #749099;
		min-height: 41px;
		margin-bottom: 0;
		display: flow-root;
		width: 100%;
		position: relative;
		&.has-pagination {
			padding-bottom: 0;
		}
		button.edit-button {
			display: inline-flex;
			padding: 0px;
			justify-content: center;
			align-items: center;
			i {
				background-image: url("../img/icons/edit-icon.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-position-x: 7px;
				background-size: 26px;
				opacity: .5;
				width: 36px;
				height: 30px;
			}
		}
	  	.widget-header-title-wrapper {
			h3 {
				line-height: 32px;
				margin: 1px 20px 0 0;
				font-size: 14px;
			}
		}
		&.font-color-white {
		  .widget-header-title-wrapper {
			h3 {
				color: white;
			}
		  }
			.pagination-info {
				color: white;
			}
		}


		.btn-group {
			margin: 0 7px 0 0;
			&.dropdown {
				.dropdown-menu {
					max-height: 300px;
					overflow-x: hidden;
					overflow-y: auto;
				}
			}
		}
		.pagination-container {
			float: right;
			margin: 0 0 3px 0;
			height: 32px;
			.pagination-info + div {
				> ul {
					margin: 1px 0 0 !important;
				}
			}
		}
		.btn.btn-secondary.pull-right {
		  &:last-child {
			margin: 0;
		  }
		}

	    button.reference-page-btn {
		  float: right;
		  height: 33px;
		}
		nx-dropdown {
			button {
				background-color: #fff !important;
				border-bottom: 3px solid #c9c9c9 !important;
				&:hover {
					background-color: #f2f2f2 !important;
				}
				&:active {
					background-color: #f2f2f2 !important;
				}
			}
			li {
				padding: 3px 20px;
				cursor: pointer;
				&:hover {
					background-color: #f2f2f2;
				}
			}
		}
		.widget-header-right-side, .widget-header-right-side-alignment {
			button.card-icon-linked {
				display: inline-flex;
				padding: 0px;
				justify-content: center;
				align-items: center;
				i {
					background-image: url("../img/icons/Linked.svg");
					background-repeat: no-repeat;
					background-position: center;
					background-position-x: 9px;
					background-size: 17px;
					opacity: .5;
					width: 34px;
					height: 32px;
				}
			}
		}
	}

	.widget-sub-header {
		padding: 3px 9px 3px 20px;
		background-color: #fff;
		min-height: 41px;
		width: 100%;
	  	border-bottom: 1px solid #dedede;

		.widget-sub-header-title-wrapper {
			h3 {
				line-height: 32px;
				margin: 1px 20px 0 0;
				font-size: 14px;
			}
		}

		.btn-plus-action-right-side {
			float: right;
			height: 28px;
			background-image url("../img/icons/icon_add_colorful.svg")
			background-size 16px 19px
			background-repeat no-repeat
			background-position 4px
			border-left: 1px solid #c9c9c9;
			border-bottom: 3px solid #c9c9c9;
			border-top: 1px solid #c9c9c9;
			border-right: 1px solid #c9c9c9;
		}
	}
	.widget-body {
		width: 100%;

		.list-container {
			position: relative;
		}
		.table {
			margin-bottom: 0;
			.tag {
				display: inline-block;
				padding: 0 5px;
				margin: 0 5px 5px 0;
			}
			&.table-mobile-optimized {
				> thead,
				> tbody {
					tr {
						th,
						td {
							padding-top: 5px;
							padding-bottom: 5px;
							table {
								> tbody {
									tr {
										td {
											display: block;
											border: 0;
											vertical-align: top;
											padding: 0 20px 0 70px;
											&:first-child {
												padding-left: 40px;
											}
											.child-toggle {
												background-size: 9px 18px;
												width: 40px;
												height: 40px;
												position: absolute;
												top: -7px;
												margin: 0;
												&.child-shown {
													background-size: 18px 9px;
												}
											}
										}
									}
								}
							}
							&.table-cell-empty {
								display: none;
							}
						}
						&.child-node {
							table {
								td {
									padding-left: 100px;
									&:first-child {
										padding-left: 70px;
									}
									.child-toggle {
										left: 30px;
									}
								}
							}
							&.child-level-2 {
								table {
									td {
										padding-left: 130px;
										&:first-child {
											padding-left: 100px;
										}
									}
								}
							}
						}
					}
				}
			}
			&.table-selectable {
				th, td {
					/*border-right: 0;
				  &:first-child {
					  border-right: 0;
				  }*/
					td {
						display: block;
						width: 100%;
						&:last-child {
							text-align: left;
						}
					}
				}
			}
			&.table-data {
				&.data-table-alternative {
					border: 0;
				}
				table {
					tr {
						td {
							&:nth-child(4) {
								text-align: left;
							}
						}
					}
				}
			}
		}
	    .no-data {
		  padding: 10px;
		}

		.rules-flow-header {
			padding: 5px 10px;
			border-top: 2px solid #F5F5F5;
			background-color: #F5F5F5;
			overflow: hidden;
		}

		@media (min-width: $screen-md-min) {
			treecontrol,
			.treecontrol {
				&.hasDropdown {
					.tree-row {
						> div {
							padding-right: 51px;
						}
					}
				}
				.tree-label {
					.dropdown {
						right: 10px;
						top: 6px;
						display: block;
					}
				}
			}

			li > div > div .tree-label i + div,
			li > div > div .tree-label i + div {
				max-width: 40%;
			}

			treeitem > ul > li > div > div .tree-label i + div,
			treeitem > ul > li > div > div .tree-label i + div {
				max-width: 40%;
			}

			.tree-container,
			.list-container {
				.tree-item-status {
					overflow: hidden;
					word-wrap: break-word;
					height: 20px;
					max-width: 100%;
				}
			}
		}

	}
	.widget-footer {
		overflow: hidden;
		.pagination-container {
			margin: 4px 0 4px 0;
			.pagination {
				margin: 4px 0 4px 0;
			}
		}
	}
	.text-widget-body {
		padding: 10px;
	}
	.new-problem-widget-content {
	  .no-problems {
		margin: 10px;
	  }
	}
	.problem-widget-content, .subgoal-widget-content {
	  > * {
		border-top-color: LightGrey;
		border-top-style: solid;
		border-top-width: 3px;
		display: block;
	  }
	  *:nth-child(1) {
		border-top-style: none;
	  }
	  .no-problems {
		margin: 10px;
	  }
	  .problem-overview {
		display: inline-flex;
		align-items: center;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 8px;
		padding-left: 5px;
		.state-chart {
		  min-width: 55px;
		  min-height: 55px;
		  max-width: 55px;
		  max-height: 55px;
		  margin: 5px;
		}
		.state-charts-separator-img {
		  background-image: url("../img/icons/chevron-right-grey.svg");
		  background-repeat: no-repeat;
		  background-position: center;
		  background-size: 100% 100%;
		}
		.state-charts-separator {
		  width: 9px;
		  height: 12px;
		}
		.problem-data {
		  flex-basis: 59%;
		  padding-left: 13px;
		  min-width: 50px;
		  word-break: break-word;
		  word-wrap: break-word;
		  .problem-name {
			font-weight: bold;
			margin-top: 5px;
			margin-bottom: 5px;
		  }
		}
		.problem-state {
		  margin-right: 40px;
		  margin-left: auto;
		  text-align right;
		}
	  }
	  .problem-subgoal-widget-content {
		> * {
		  display: block;
		}
		.subgoal-overview {
		  display: inline-flex;
		  align-items: center;
		  width: 100%;
		  padding-top: 6px;
		  padding-bottom: 4px;
		  .score-chart {
			min-width: 55px;
			min-height: 55px;
			max-width: 55px;
			max-height: 55px;
			margin: 5px;
		  }
		  .subgoal-name {
			flex-basis: 100%;
			margin-left: 10px;
			word-break: break-word;
			word-wrap: break-word;
		  }
		  .subgoal-date {
			margin-right: 10px;
			min-width: 80px;
			text-align: right;
		  }
		  nx-dropdown {
			.dropdown {
			  .dropdown-menu {
				margin: 0;
				border-radius: 0;
				border: 1px solid #ccc;
				padding: 0;
				box-shadow: 0 0 0;
				white-space: nowrap;
				left: auto;
				right: 0;
				li {
				  border-bottom: 1px solid #ccc;
				  padding: 8px 25px 8px 8px !important;
				  cursor: pointer;
				  ul {
					position: absolute;
					right: -100%;
					width: 100%;
					top: -1px;
					background: #fff;
					border: 1px solid #ccc;
					display: none;
					li {
					  &:last-child {
						border-bottom: 0;
					  }
					}
				  }
				  &:hover {
					background: $item-hover-color;
					> span {
					  color: #43b02a;
					}
					ul {
					  display: block;
					}
				  }
				}
			  }
			  .btn{
				width: 30px;
				height: 30px;
				background: transparent;
				border-left: 1px solid #ccc;
				border-radius: 0;
				.caret {
				  position: absolute;
				  left: 10px;
				  top: 14px;
				  border-top-style: dashed;
				}
			  }
			  .btn:hover {
				background: #dfe6da;
			  }
			}
		  }
		}
	  }
	}

  	.visitation-widget-content {
	  .no-visitations {
		margin: 15px;
	  }
	  .no-relevant-groups {
		&:hover {
		  text-decoration: underline;
		}
		margin: 15px;
		cursor: pointer;
	  }
	}
  	.visitation-widget-content.visitation-widget-content-width-xs
    .visitation-widget-content.visitation-widget-content-width-sm
    .visitation-widget-content.visitation-widget-content-width-md
    .visitation-widget-content.visitation-widget-content-width-lg {
	  .visitation-overview-container {
		> * {
		  width: 50%;
		  &:nth-child(odd) {
			border-right-color: LightGrey;
			border-right-style: solid;
			border-right-width: 1px;
		  }
		  &:nth-child(even) {
			border-left-color: LightGrey;
			border-left-style: solid;
			border-left-width: 1px;
			margin-left: -1px;
		  }
		  &:nth-child(2) {
			border-top: none;
		  }
		}
		.dummy-visitation-overview {
		  display: inline-block;
		}
	  }
	}
	.condition-activity-widget {
	  cursor: pointer;
	  display: inline-flex;
	  &:hover {
		background-color: #ebf2e6;
	  }
	  .state-panel {
		width: 120px;
		min-width: 120px;
		text-align: center;
		font-weight: bold;
		.state-chart {
		  margin-bottom: -20px;
		  min-height: 120px;
		  max-height: 120px;
		  min-width: 120px;
		  max-width: 120px;
		}
		.condition-state-chart-label {
		  min-height: 12px;
		}
		.state-charts-separator {
		  background-image: url("../img/icons/chevron-down-grey.svg");
		  background-repeat: no-repeat;
		  background-position: center;
		  background-size: 100% 100%;
		  width: 20px;
		  height: 11px;
		  margin-left: auto;
		  margin-right: auto;
		  margin-top: 42px;
		}
		.state {
		  margin: 20px;
		  .state-value {
			font-weight: normal
		  }
		}
	  }
	  .condition-info-panel {
		display: block;
		padding-top: 5px;
		.condition-info-item {
		  margin: 10px 50px 20px 10px;
		  .condition-info-item-label {
			font-weight: bold;
			margin-bottom: 5px;
		  }
		  .corresponding-condition-row {
			white-space: nowrap;
			.corresponding-condition-icon {
			  margin-right: 5px;
			}
			.corresponding-condition-name {
			  margin-right: 20px;
			  vertical-align: text-bottom;
			}
		  }
		}
		.condition-last-updater {
		  color #9e9e9e
		}
	  }
	}
}

.problem-widget-content {
  .row {
	cursor: pointer;
	min-height: 43px;
	position: relative;
	padding: 8px 8px 7px 0;
	&:hover {
	  background-color: #ebf2e6;
	  .dropdown {
		display: inline-block;
		.ui-select-container {
		  display: block;
		}
	  }
	  .row-label {
		text-decoration: underline;
	  }
	}
  }
  .list-container {
	padding-left: 20px;
	.organization-name {
	  top: -3px;
	  position: relative;
	}
	.standard-tree-item {
	  display: block;
	  margin-bottom: 5px;
	  i {
		vertical-align: top;
	  }
	  i + div {
		display: inline-block;
		width: calc(100% - 50px);
		top: 3px;
	  }
	  .visible-lg-inline {
		display: inline !important;
	  }
	  .tree-item-status {
		display: none;
	  }
	}
  }
}

//will hide additional info on eop problem widget when width drops below 500px
.eop-problems-widget-width-xxs .problem-widget-content .list-container .standard-tree-item .visible-lg-inline {
   display: none !important;
}

.problem-subgoal-widget-content, .subgoal-widget-content, .visitation-overview-container .subgoal-overview {
  .row {
 	cursor: pointer;
	min-height: 43px;
	border-bottom: 1px solid #dedede;
	position: relative;
	padding: 8px 8px 7px 0;
	&:hover {
		background-color: #ebf2e6;
		.dropdown {
			display: inline-block;
			.ui-select-container {
				display: block;
			}
		}
	  .row-label {
		text-decoration: underline;
	  }
  	}
  }
}

.calendar-widget {
	.pagination-info {
		float: left;
		position: relative;
		top: 9px;
		margin-right: 10px;

		+ div {
			float: left;
			ul {
				margin: 4px 0 0 0;
			}
		}
	}
}

.pathway-widget {
	.pagination-info {
		float: left;
		position: relative;
		top: 9px;
		margin-right: 10px;

		+ div {
			float: left;
			ul {
				margin: 4px 0 0 0;
			}
		}
	}
	.pagination {
		.disable-link {
			cursor: not-allowed;
			pointer-events: none;
			color: grey;
			opacity: .5;
		}
		a:focus {
			background-color: #fff;
			border: 1px solid #c9c9c9;
		}
		a:hover {
			background-color: #eee;
		}
	}
}

#nova-link-container {
  position:fixed;
  right:12%;
  top:7%;
  height:90%;
  width:400px
  background-color:white;
  box-shadow: 4px 6px 35px rgba(0, 0, 0, 0.5);
  z-index: 1100;
}

#nova-link-container-header {
  text-align: right;
  background-color: #607d8b;
  padding-top: 5px;
  padding-right: 10px;
  color: #ffffff;
}

#nova-link-iframe {
  border:none;
  position: relative;
  height: 100%;
  width: 100%;
}

.nova-link-consent-missing {
  text-decoration: underline;
}

.activity-dashboard-modal-header {
  .plus-button.btn {
	height: 36px;
  }
}

.activity-dashboard {
	.btn-multi-action span.caret-label-left {
		display inline-block
		background-image url("../img/icons/icon_add_colorful.svg")
		background-size 16px 16px
		background-repeat no-repeat
		text-indent -9999px
		vertical-align middle
		width 20px
		height 17px
		overflow hidden
	}
	button.btn-single-action {
		background-image url("../img/icons/icon_add_colorful.svg")
		background-size 16px 16px
		background-repeat no-repeat
		text-indent -9999px
		background-position 4px
	}
  .single-patient-condition-details-width-xxs {
	.form-group > * {
	  float: none;
	  margin-bottom: 5px;
	  padding: 0px;
	  width: 100%;
	}
	.form-group > label > * {
	  vertical-align: text-top;
	}
  }
}

.modal .modal-dialog.modal-full .modal-content .patient-condition-entity-dialog {
  .modal-footer {
	display: none;
  }
  .modal-scroll-area > .entity-dialog-container-1 {
	height: 100%;
	& > .entity-dialog-container-2 {
	  height: 100%;
	  .modal-body {
		height: 100%;
		flex-flow: column;
	  }
	}
  }
}

.patient-condition-dashboard-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  .patient-conditions-widget-wrapper {
	overflow-y: auto;
  }
  .patient-condition-dashboard {
	overflow-y: auto;
  }
  .gutter.gutter-horizontal {
	background-color: #eee;
	cursor: ew-resize;
	background-image: url("../img/slider.png");
	background-repeat: no-repeat;
	background-position: 0 30%;
  }
  .smaller-buttons-group button {
	padding-left: 8px;
	padding-right: 8px;
  }
  .measurement-widget {
	.widget-header {
	  display: flex;
	  justify-content: space-between;
	  .widget-header-left-side {
		.widget-header-title-wrapper {
		  display: inline-block;
		  h3 {
			float: none;
			display: inline-block;
		  }
		}
	  }
	  .widget-header-right-side-alignment {
		display: flex;
		align-items: flex-start;
		.pagination-container {
		  display: inline-block;
		  float: unset;
		  ul {
			margin: 2px 10px;
		  }
		}
	  }
	}
  }
}

.not-linked-icon {
	background-image: url("../img/icons/Not-Linked.svg");
	background-size: 16px 16px;
	background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 7px;
}
.not-linked-icon-placeholder {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 7px;
}

.patient-conditions-quick-filters {
  height: 0px;
  width: 100%;
  overflow-y: hidden;
  &.visible {
	height: auto;
	overflow-y: visible;
	border-bottom: 1px solid #B0B0B0;
  }
}

.patient-conditions-widget-content {
	width: 100%;
	height: 100%;
}

.page-as-modal {
  & > .modal {
	display: block;
	& > .modal-dialog.modal-full {
	  position: fixed;
	}
	// bootstrap uses this formula for calculating z-index: 1050 + $$topModalIndex*10
	// first modal managed by bootstrap will have z-index = 1050
	// since we are creating fake modal bootstrap is unaware of we have to give him z-index lower than 1050
	// so "bootstrap" modals will not hide beneath it
	// also z-index has to be lower than 1040 because this is the value at which modal's background is rendered
	z-index: 1030;
  }
}

.sundhedsjournalen-link-img {
	background-image url("../img/icons/Sundhedsjournalen-link.svg")
	height 100px
	background-position: center center;
	background-repeat: no-repeat;
}
button.btn-flow-action {
  padding: 16px 12px;
}

.addMeasurementButton{
  margin-right: 30px;
  width: 30px;
  background-position: center !important;
  background-image: url("../img/icons/icon_add_colorful.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.measurement-border{
  display: none;
}

.measurement-widget {
  .widget-header {
	display: flex;
	background-color: #edf9fb;
  }
  .widget-header-right-side {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	text-align center;
	min-width: 0%;
  }
  .widget-header-right-side-alignment {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	text-align center;
	min-width: 0%;
	.inline-buttons {
	  display: block;
	}
  }
  .widget-header-left-side {
	display: flex;
	align-items: center;
  }
  &.widget .widget-header .measurement-actions-dropdown .btn-group {
	margin-right: 0px;
  }
  .widget-header-left-side {
	background: #EDF9FB;
  }
  .widget-header-title-wrapper h3 {
	margin-left: 3px;
	padding-left: 0px;
  }
  .widget-body .dropdown-menus-right{
	border: 0px;
  }
  .widget-header-right-side-alignment{
	background: #EDF9FB;
  }

  .dropdown-menus-right{
	border: 0px;
  }
  .new-button{
	margin-right: 20px;
  }
  .pagination-container{
	background: #EDF9FB;
  }

  .btn-group {
	&.dropdown {
	  .dropdown-menu {
		overflow-x: hidden;
		overflow-y: auto;
		button {
		  background-color: #fff !important;
		  border-bottom: 0px solid #c9c9c9 !important;
		}
		li {
		  border-bottom: none;
		  padding: 0 !important;
		  cursor: pointer;
		}
	  }
	}
  }

}

.measurement-table {
  .measurement-head {
	background-color: transparent !important;
	tr {
	  th {
		background-color : #ffffff;
		text-align: center;
		border: 2px solid #DEDEDE;
		border-left: 0;
	  }
	  .placeholder-column{
		border: 0;
		background: transparent;
	  }
	}
  }
  tr {
	td {
	  border: 2px solid #DEDEDE;
	  background: #fff;
	  background-clip: padding-box !important;
	  text-align: center;
	}
	td:first-child {
	  border-left: 0;
	  text-align: left;
	}
	th {
	  border-top: 2px solid #DEDEDE !important;
	  background: #fff;
	}
	th:last-child {
	  border-right: 0 !important;
	}
	td:last-child {
	  border-right :0  !important;
	}
	.boundaryExceeded {
	  background-color: #f3df81 !important;
	}
	&:hover {
	  background-color: transparent !important;
	  td {
		background-color: #fff !important;
	  }
	  td:first-child {
		background-color: #f5f5f5 !important;
	  }
	  .placeholder-column{
		border: 0;
		background-color: transparent !important;
	  }
	  .dropdown {
		display: inline-block;
	  }
	  .placeholder-column{
		border: 0
		background-color: transparent !important;
	  }
	  .boundaryExceeded {
		background-color: #f3df81 !important;
	  }
	}
	.placeholder-column{
	  border: 0;
	  background: transparent;
	}
  }

  @media (max-width: $screen-md-max) {
	.field-label {
	  width: 100px;
	}
	td, th {
	  padding: 5px 5px !important;
	  overflow-wrap: break-word;
	}
  }
}
.radio-patient-consent-dialog{
	padding-bottom: 10px;
}
