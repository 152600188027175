@require "core/variables";

.prototype {
	overflow: hidden;
	.w-clearfix:before,
	.w-clearfix:after {
		content: " ";
		display: table
	}
	.w-clearfix:after {
		clear: both;
	}
	.w-form-done,
	.w-form-fail {
		display: none;
	}
	.w-radio-input {
		margin-right: 5px;
	}
	h1 {
		margin-top: 20px;
		margin-bottom: 10px;
		float: left;
		font-size: 38px;
		line-height: 44px;
		font-weight: 700;
	}
	h2 {
		margin-top: 15px;
		margin-bottom: 0px;
		padding-right: 20px;
		float: left;
		border-right: 1px solid #c9c9c9;
		font-size: 2em;
		line-height: 36px;
		font-weight: 700;
	}
	h3 {
		margin: 3px 0 0;
		float: left;
		color: $title-color;
		font-size: 1.7em;
		line-height: 30px;
		font-weight: 700;
		letter-spacing: 0.02em;
		text-transform: none;
	}
	@media (min-width: $screen-sm-min) {
		h3 {
			width: 28%;
		}
	}
	h4 {
		margin-top: 0px;
		margin-right: 20px;
		margin-bottom: 0px;
		padding-left: 35px;
		float: left;
		font-size: 1em;
		line-height: 24px;
		font-weight: 700;
	}
	h5 {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
	}
	h6 {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 12px;
		line-height: 18px;
		font-weight: 700;
	}
	p {
		margin-bottom: 5px;
	}
	a {
		color: #00e;
		text-align: left;
	}
	.button {
		display: inline-block;
		margin-right: 15px;
		padding: 6px 19px;
		float: right;
		border-bottom: 3px solid #34911f;
		border-radius: 2px;
		background-color: #43b02a;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		font-family: interface, sans-serif;
		color: white;
		font-size: 1em;
		font-weight: 400;
		text-align: center;
		letter-spacing: 0.03em;
		text-decoration: none;
	}
	.button:hover {
		border-bottom-color: #0b3233;
		background-color: #155758;
	}
	.button.buttonless {
		height: 36px;
		margin-right: 0px;
		float: left;
		border-right: 1px solid #e6e6e6;
		border-bottom-color: #c9c9c9;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		background-color: white;
		color: #666;
	}
	.button.buttonless:hover {
		background-color: #f2f2f2;
		color: $default-text-color;
	}
	.button.buttonless.btncenter {
		height: 36px;
		border-radius: 0px;
	}
	.button.buttonless.btncenter.btnarrow {
		width: 35px;
		height: 36px;
		min-width: 35px;
		float: left;
		border-right-color: #c9c9c9;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 50% 50%;
		background-size: 15px;
		background-repeat: no-repeat;
	}
	.button.buttonless.btncenter.btnarrow.inline {
		width: 25px;
		height: 25px;
		min-width: 25px;
		margin-top: 3px;
		margin-bottom: 2px;
		padding-right: 15px;
		padding-left: 15px;
		float: left;
		border-left: 1px none #c9c9c9;
		border-bottom-width: 1px;
		background-color: transparent;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/55507e53ea1dbd76158630e8_burger_dark.svg);
	}
	.button.buttonless.btncenter.btnarrow.inline:hover {
		background-color: white;
	}
	.button.buttonless.btncenter.btnarrow.withh3 {
		width: 30px;
		height: 30px;
		min-width: 30px;
		margin-top: 4px;
		padding: 11px;
		float: left;
		border-left: 1px solid #c9c9c9;
		border-right-style: none;
		border-bottom-style: none;
		background-color: transparent;
		background-size: 12px;
	}
	.button.buttonless.btnlast {
		margin-right: 14px;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
	}
	.button.buttonless.btnlast.btnarrowdown {
		margin-right: 2%;
		padding-right: 47px;
		float: right;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 88% 52%;
		background-size: 15px;
		background-repeat: no-repeat;
	}
	.button.btnsoeg {
		margin-top: 4px;
		float: left;
	}
	.letfnav {
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 10000;
		width: 45px;
		height: 100%;
		min-width: 40px;
		background-color: #155758;
		background-image: -webkit-linear-gradient(#155758, #0b4142);
		background-image: linear-gradient(#155758, #0b4142);
	}
	.topnav {
		width: 100%;
		height: 45px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		background-color: white;
		box-shadow: rgba(0, 0, 0, 0.34) 1px 1px 3px 0px;
	}
	.logo {
		width: 110px;
		height: 25px;
		margin-top: 10px;
		margin-right: 10px;
		margin-left: 1%;
		float: left;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555b0976290a9b4224db2e89_kmd_Avaleo_logo.svg);
		background-position: 0px 50%;
		background-repeat: no-repeat;
	}
	.divname {
		height: 45px;
		min-width: 300px;
		padding-top: 13px;
		padding-right: 15px;
		padding-left: 14px;
		float: right;
		border-left: 1px solid white;
		text-align: right;
	}
	.sectionclientheader {
		padding: 5px 10px;
		background-color: rgba(158, 196, 134, 0.20);
		position: relative;
	}
	@media (min-width: $screen-sm-min) {
		.sectionclientheader {
			padding: 5px 20px;
		}
	}
	.divider {
		color: #7d7d7d;
		font-weight: 100;
	}
	.cpr {
		margin-top: 26px;
		margin-right: 22px;
		margin-left: 15px;
		padding-right: 14px;
		float: left;
		border-right: 1px solid #c9c9c9;
		font-family: interface, sans-serif;
		color: #919191;
		font-size: 1.3em;
		font-weight: 300;
		letter-spacing: 0.05em;
	}
	.divinfoblock {
		height: 80px;
		min-height: 70px;
		margin: 0 15px 0 0;
		float: left;
		border-radius: 2px;
		background-color: white;
		box-shadow: rgba(0, 0, 0, 0.29) 0px 0px 4px 0px;
	}
	.divinfoblock.alertblok {
		height: 80px;
		min-height: 80px;
		padding-top: 5px;
		float: left;
		border-top: 5px solid #d90f0f;
		border-bottom: 10px none #bd0202;
		background-color: #fff9ba;
	}
	.divinfoblock.redblock {
		width: 12%;
		background-color: white;
	}
	.divinfoblock.fotoblock {
		width: 80px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a259b849c1a954cc30f1c_nancy.jpg);
		background-size: cover;
	}
	@media (min-width: $screen-md-min) {
		.divinfoblock {
			margin-bottom: 15px;
		}
	}
	.celltoptwo {
		width: 100%;
		height: 45px;
		padding-top: 5px;
		padding-right: 10px;
		padding-left: 5px;
		float: left;
		border-bottom: 1px solid #ebebeb;
	}
	.cellleftone {
		width: 50%;
		height: 35px;
		padding: 10px;
		float: left;
		border-right: 1px solid #ebebeb;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/55507b76ea1dbd761586305b_Telephone.svg);
		background-position: 5px 50%;
		background-size: auto 20px;
		background-repeat: no-repeat;
	}
	.cellleftone:hover {
		background-color: rgba(67, 176, 42, 0.09);
	}
	.cellrightone {
		width: 50%;
		height: 35px;
		padding: 10px;
		float: right;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/55507bece251967715bfd9d0_Smartphone.svg);
		background-position: 3px 50%;
		background-size: auto 20px;
		background-repeat: no-repeat;
	}
	.cellrightone:hover {
		background-color: rgba(67, 176, 42, 0.09);
	}
	.textadress {
		padding-left: 27px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/5548a8bc70e77eb743035223_Home.svg);
		background-position: 1px 50%;
		background-size: auto 19px;
		background-repeat: no-repeat;
		font-size: 1em;
		line-height: 1.2em;
		font-weight: 400;
	}
	.infocontainer {
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
		float: left;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
	}
	@media (min-width: $screen-xs-min) {
		.infocontainer {
			//margin: 0 0 0 100px;
		}
	}

	.scrolcontainer {
		padding: 2px;
	}
	.linkname {
		color: #858585;
		text-decoration: none;
	}
	.linkname.logud {
		margin-left: 15px;
		padding-left: 15px;
		border-left: 1px solid #a3a3a3;
		text-decoration: underline;
	}
	.linkname.logud:hover {
		color: black;
		text-decoration: none;
	}
	.sectionnav {
		width: 100%;
		height: auto;
		padding: 5px 10px 0;
		float: left;
		border-top: 1px solid #b7c4c8;
		border-bottom: 1px solid #b7c4c8;
		background-color: rgba(33, 168, 198, 0.36);
	}
	@media (min-width: $screen-xs-min) {
		.sectionnav {
			padding: 5px 20px 0;
		}
	}
	.sectionnav.inslidein {
		padding-bottom: 25px;
		border-radius: 0px;
	}
	.sectionlist {
		width: 100%;
	}
	.sectionlist.list80 {
		width: 75%;
		margin-top: 0px;
		float: left;
		border-right: 1px solid #ebebeb;
	}
	.sectionlist.list20 {
		width: 20%;
		margin-top: 85px;
		margin-left: 1%;
		float: right;
		background-color: #ebebeb;
	}
	.sectionlist.listoverlay {
		overflow-x: visible;
		overflow-y: visible;
		width: 75%;
		margin-top: 0px;
		float: left;
		border-right: 1px solid #dedede;
	}
	.sectionlist.listoverlay.listsection {
		width: 70%;
	}
	.divlines {
		width: 100%;
		min-height: 25px;
		padding-top: 5px;
		padding-bottom: 6px;
		padding-left: 1%;
		float: left;
		border-bottom: 1px solid #dedede;
		background-color: rgba(201, 201, 201, 0.09);
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 16px 50%;
		background-size: 10px;
		background-repeat: no-repeat;
		//display: none;
	}
	.divlines:hover {
		background-color: rgba(158, 196, 134, 0.2);
	}
	.divlines.color {
		background-color: rgba(158, 196, 134, 0.09);
	}
	.divlines.divlinelevel1 {
		min-height: 35px;
		padding-left: 1%;
		background-color: white;
	}
	.divlines.divlinelevel1:hover {
		background-color: rgba(158, 196, 134, 0.2);
	}
	.divlines.divlinelevel1.indented {
		padding-left: 3%;
		background-position: 47px 50%;
	}
	.divlines.divlinelevel1.noarrow {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 0%;
		background-image: none;
		background-position: 0% 0%;
		background-size: auto;
		background-repeat: repeat;
		background-attachment: scroll;
	}
	.divlines.divlinelevel1.noarrow.open {
		background-color: #ebebeb;
	}
	.divlines.divlinelevel1.noarrow.open:hover {
		background-color: rgba(158, 196, 134, 0.24);
	}
	.divlines.divlinelevel2 {
		padding-left: 6%;
		background-color: white;
		background-image: none;
		background-position: 0% 0%;
		background-size: auto;
		background-repeat: repeat;
	}
	.divlines.divlinelevel2:hover {
		background-color: rgba(158, 196, 134, 0.09);
	}
	.divlines.divlinelevel2.excel {
		padding-left: 5%;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 47px 50%;
		background-size: 10px;
		background-repeat: no-repeat;
	}
	.divlines.divlinelevel2.excel.level2noarrow {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 0%;
		background-image: none;
		background-position: 0% 0%;
		background-size: auto;
		background-repeat: repeat;
		background-attachment: scroll;
	}
	.divlines.arrowup {
		padding-left: 1%;
		background-color: rgba(201, 201, 201, 0.24);
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b61b9f52065916d243dad_arrowUp.svg);
	}
	.searchform {
		width: 35%;
		min-width: 200px;
		float: left;
	}
	.searchbtn {
		float: left;
	}
	.searchfield {
		width: 70%;
		height: 34px;
		margin-top: 4px;
		margin-right: 10px;
		float: left;
	}
	.form {
		padding-left: 14px;
		border-left: 1px none #ebebeb;
		text-align: left;
	}
	.divmenuicons {
		width: 45px;
		height: 45px;
		float: left;
		border-bottom: 1px solid #193f40;
		background-color: rgba(255, 255, 255, 0.2);
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/5548b933a9c4f8b5436f5ce2_burger.svg);
		background-position: 50% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
	}
	.divmenuicons.iconburger {
		border-bottom: 1px solid #2b6768;
	}
	.divmenuicons.home {
		width: 45px;
		margin-bottom: -1px;
		float: left;
		border-bottom-color: white;
		background-color: transparent;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/5548baf4870d13647262f9fc_Home_fill.svg);
		background-size: 18px;
		opacity: 0.3;
	}
	.divmenuicons.home:hover {
		border-bottom-color: rgba(255, 255, 255, 0.36);
		background-color: rgba(0, 0, 0, 0.18);
		opacity: 1;
	}
	.divmenuicons.home.calendar {
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b1f07f52065916d243449_Calendar.svg);
		background-size: 20px;
	}
	.divmenuicons.home.statistics {
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b1f95849c1a954cc32b7f_stats.svg);
		background-size: 20px;
	}
	.divmenuicons.home.mail {
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b20a8abf193924ca3e9fd_Envelope.svg);
		background-size: 20px;
	}
	.divmenuicons.home.people {
		height: 44px;
		margin-top: 0px;
		padding-top: 0px;
		border-bottom-color: rgba(255, 255, 255, 0.52);
		background-color: rgba(255, 255, 255, 0.24);
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b226df52065916d243489_people.svg);
		background-size: 22px;
		opacity: 1;
	}
	.cellfull {
		width: 100%;
		height: auto;
		padding: 5px 10px 10px;
	}
	.txtwhite {
		color: #bd0202;
	}
	.nameholder {
		width: 100%;
		//margin-bottom: 2px;
		//padding-left: 0px;
		float: left;
	}
	.profilepic {
		display: none;
	}
	@media (min-width: $screen-xs-min) {
		.profilepic {
			position: absolute;
			top: 84px;
			left: 2%;
			width: 80px;
			height: 80px;
			float: left;
			background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a259b849c1a954cc30f1c_nancy.jpg);
			background-size: contain;
			//display: block;
		}
	}
	.searchiconsmall {
		display: none;
		width: 50px;
		height: 45px;
		float: right;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/5548b177870d13647262f878_Magnifi-Glass2.svg);
		background-position: 50% 50%;
		background-size: auto 25px;
		background-repeat: no-repeat;
	}
	.navholder {
		display: none;
	}
	@media (min-width: $screen-xs-min) {
		.navholder {
			width: 100%;
			height: 60px;
			min-height: 50px;
			display: block;
		}
	}
	.textdate {
		margin-right: 2%;
		padding-top: 5px;
		float: right;
		color: #999;
	}
	.textdate.texrdatebold {
		margin-right: 2%;
		color: #474747;
		font-weight: 700;
	}
	.textdate.dateboxed {
		width: 100%;
		float: left;
	}
	.h4level3 {
		width: 80%;
		padding-top: 3px;
		padding-right: 0px;
		padding-left: 0px;
		color: #9e9e9e;
		font-weight: 300;
		letter-spacing: 0.02em;
	}
	.h4level3.h4profile {
		padding-left: 30px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555f0792324cabba6409fc29_Male.svg);
		background-position: 0px 50%;
		background-size: auto 20px;
		background-repeat: no-repeat;
	}
	.h4level3.h4profile.boxed {
		width: 100%;
		margin-left: 20px;
		padding-top: 7px;
		padding-left: 35px;
		line-height: 1.2em;
	}
	.h4level3.h4medicine {
		width: 81%;
		padding-left: 30px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555f097c1b40d3860bbb923f_pill.svg);
		background-position: 0px 50%;
		background-size: auto 29px;
		background-repeat: no-repeat;
	}
	.h4level3.h4medicine.boxed {
		width: 100%;
		margin-left: 20px;
		padding-left: 35px;
	}
	.h4level3.h3doc {
		padding-left: 30px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555f0918821cab850bceb8b8_doc.svg);
		background-position: 3px 50%;
		background-size: auto 20px;
		background-repeat: no-repeat;
	}
	.h4level3.h3doc.boxed {
		width: 87%;
		margin-right: 13px;
		margin-left: 20px;
		padding-left: 35px;
		background-position: 2px 6px;
		background-size: 16px;
	}
	.h4level3.h3doc.boxed.colored {
		margin-left: 20px;
		float: left;
		line-height: 1.4em;
	}
	.foldoutbtn {
		position: absolute;
		width: 45px;
		height: 45px;
		float: left;
	}
	.h4process {
		margin-top: 3px;
		margin-left: 32px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555eff2c1b40d3860bbb9133_Folder.svg);
		background-position: 0px 50%;
		background-size: auto 85%;
		background-repeat: no-repeat;
	}
	.h4process:hover {
		text-decoration: underline;
	}
	.h4process.h4boxed {
		margin-left: 20px;
	}
	.txtblack {
		color: black;
		font-weight: 700;
	}
	.colorblocks {
		display: inline-block;
		width: 20px;
		height: 19px;
		margin-top: 4px;
		margin-right: 12px;
		margin-left: 4px;
		float: left;
		background-color: #9ec486;
	}
	.colorblocks.color2 {
		background-color: #d90f0f;
	}
	.colorblocks.small {
		width: 2px;
		height: 25px;
		margin-left: 21px;
		float: left;
	}
	.linkinline {
		color: $default-text-color;
	}
	.linkinline:hover {
		color: #21a8c6;
		text-decoration: none;
	}
	.txtcards {
		padding-left: 21px;
		font-size: 1em;
		line-height: 1.2em;
	}
	.txtcards.inactive {
		color: rgba(51, 51, 51, 0.29);
	}
	.txtcards.txtnospace {
		padding-left: 0;
	}
	.divcontextualbtn {
		width: 25px;
		height: 25px;
		float: left;
	}
	.h2citizen {
		padding-left: 0px;
	}
	.buttoncontextual {
		width: 35px;
		height: 40%;
		min-height: 40px;
		min-width: 35px;
		float: left;
		border-right: 1px none #c9c9c9;
		border-bottom: 1px none #c9c9c9;
		border-left: 1px solid #c9c9c9;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/55507e53ea1dbd76158630e8_burger_dark.svg);
		background-position: 50% 50%;
		background-size: 16px;
		background-repeat: no-repeat;
	}
	.buttoncontextual:hover {
		background-color: white;
	}
	.buttoncontextual.small {
		position: relative;
		height: 30px;
		min-height: 30px;
	}
	.buttoncontextual.small.incontainer {
		position: relative;
		left: 0px;
		top: 0px;
		display: block;
		width: 35px;
		min-width: 35px;
		float: none;
		background-color: transparent;
	}
	.buttoncontextual.small.incontainer:hover {
		background-color: white;
	}
	.buttoncontextual.topmenusmall {
		display: none;
	}
	.tablecells {
		height: auto;
		padding-top: 3px;
		padding-bottom: 3px;
		padding-left: 3%;
		border-bottom: 1px solid #dedede;
	}
	.tablecells:hover {
		background-color: rgba(235, 235, 235, 0.56);
	}
	.tablecells.tabledivider {
		min-height: 30px;
		padding-top: 8px;
		padding-bottom: 10px;
		border-top: 1px none #b7c4c8;
		border-bottom-width: 3px;
		border-bottom-color: #b7c4c8;
		background-color: rgba(33, 168, 198, 0.09);
	}
	.tablecells.tabledivider.history {
		margin-bottom: 6px;
		border-bottom: 1px none rgba(218, 85, 15, 0.36);
		background-color: rgba(33, 168, 198, 0);
		font-size: 0.8em;
	}
	.tablecells.tabledivider.overlaydivider {
		padding-left: 2%;
	}
	.tablecells.nolines {
		padding-top: 0px;
		border-top-style: none;
		border-bottom-style: none;
	}
	.tablecells.nolines.colorcell {
		padding-top: 3px;
		padding-bottom: 3px;
		border-bottom-style: solid;
	}
	.tablecells.nolinebtm {
		min-height: 35px;
		padding-top: 15px;
		border-bottom-style: none;
	}
	.tablecells.tablelast {
		padding-bottom: 15px;
	}
	.tablecells.terminal {
		min-height: 80px;
	}
	.tablecells.overlaycells {
		margin-bottom: 0px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 2%;
	}
	.h5tabelheader {
		margin-top: 0px;
		margin-right: 25px;
		margin-bottom: 0px;
		float: left;
		font-size: 1.2em;
	}
	.h5tabelheader.h5tabelsub {
		padding-top: 8px;
		font-size: 1em;
		font-weight: 400;
	}
	.h5tabelheader.h5tabelsub.statsu {
		font-weight: 700;
	}
	.inlineform {
		width: 100%;
		padding: 0px;
	}
	.foldoutmenu {
		width: 50%;
		height: auto;
		min-height: 30px;
		margin-top: 2px;
		margin-left: 0%;
		float: left;
	}
	.foldoutmenu.foldout20 {
		width: 20%;
	}
	.formwrapper {
		width: 54%;
		height: 35px;
		margin-bottom: 0px;
		float: left;
	}
	.cellstitel {
		width: 36%;
		float: left;
	}
	.cellstitel.status {
		width: 55%;
		margin-bottom: 9px;
	}
	.cellstitel.cellheader {
		width: 95%;
	}
	.cellstitel.cellheader.history {
		padding-top: 2px;
		padding-left: 8px;
	}
	.cellstitel.slidein {
		width: 36%;
	}
	.inputfield {
		width: 50%;
		height: 30px;
		max-height: 35px;
		margin-top: 4px;
		padding: 6px;
	}
	.inputfield.input20 {
		width: 25%;
		margin-right: 10px;
		margin-bottom: 0px;
		padding: 5px;
		float: left;
		font-size: 1em;
	}
	.inputfield.input5 {
		width: 10%;
		margin-right: 10px;
		padding: 5px;
		float: left;
		font-size: 1em;
		text-align: center;
	}
	.checkboxlabel {
		margin-left: 6px;
		font-size: 1em;
	}
	.checkfield {
		width: 28px;
		height: 20px;
	}
	.fieldcheckbox {
		padding-top: 5px;
	}
	.div25 {
		width: 25%;
		height: 100%;
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
		float: left;
		border-bottom: 1px solid #da550f;
		background-color: rgba(218, 85, 15, 0.03);
	}
	.div25.divslidein {
		width: 30%;
	}
	.txthistorie {
		margin-top: 5px;
		margin-bottom: 24px;
		padding-right: 12px;
		padding-left: 9px;
		font-size: 1em;
		line-height: 1.4em;
	}
	.date {
		color: #9e9e9e;
	}
	.inlinemenu {
		position: relative;
		left: 0px;
		top: 0px;
		bottom: -22px;
		z-index: 100;
		display: none;
		width: 260px;
		height: auto;
		float: none;
		border: 1px solid #c9c9c9;
		background-color: white;
	}
	.inlinemenu.im2 {
		display: block;
	}
	.inlinemenuholder {
		width: 55px;
		height: 30px;
		min-height: 30px;
		min-width: 35px;
		float: left;
		opacity: 0;
	}
	.submenublock {
		width: 100%;
		min-height: 40px;
		padding: 10px;
	}
	.submenublock:hover {
		background-color: #ebebeb;
	}
	.inlinemenulinks {
		width: 100%;
		min-height: 40px;
		color: $default-text-color;
		font-size: 1em;
		text-decoration: none;
	}
	.inlinemenulinks:hover {
		text-decoration: underline;
	}
	.inlinemenulinkholder {
		width: 100%;
		min-height: 40px;
		padding: 9px;
		border-bottom: 1px solid #c9c9c9;
	}
	.inlinemenulinkholder:hover {
		background-color: rgba(158, 196, 134, 0.2);
	}
	.linklist {
		float: none;
		color: black;
		text-decoration: none;
	}
	.linklist:hover {
		color: #155758;
		text-decoration: underline;
	}
	.topmenuholder {
		width: auto;
		min-height: 40px;
		min-width: 150px;
		float: right;
	}
	.inlinetopmenu {
		position: relative;
		min-width: 200px;
	}
	.contextmenuholder {
		width: 70%;
		float: right;
		margin: 0 0 5px 0;
	}
	.contextmenuholder.inslidein {
		width: 100%;
	}
	.foldouthome {
		position: relative;
		left: 45px;
		top: 43px;
		z-index: 10;
		display: none;
		width: auto;
		height: 45px;
		min-height: 45px;
		min-width: 150px;
		margin-top: 1px;
		padding-top: 11px;
		padding-left: 2px;
		float: none;
		background-color: #155758;
		text-align: left;
	}
	.linkfoldoutmenu {
		position: relative;
		left: -40px;
		top: 0px;
		display: inline-block;
		padding-left: 11px;
		float: none;
		color: white;
		font-size: 1em;
		font-weight: 300;
		text-align: left;
		letter-spacing: 0.02em;
		text-decoration: none;
		list-style-type: none;
	}
	.foldoutborgere {
		position: relative;
		left: 45px;
		top: 90px;
		display: none;
		height: 45px;
		min-width: 150px;
		padding-top: 11px;
		padding-left: 1px;
		float: none;
		background-color: #155758;
	}
	.overlay {
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 20000;
		display: block;
		width: 100%;
		height: 100%;
		margin-top: 0%;
		margin-left: 0%;
		border-style: solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.09);
		background-color: rgba(0, 0, 0, 0.6);
	}
	.overlay.slidein {
		left: auto;
		top: 0px;
		right: 0px;
		width: 75%;
	}
	.overlaycontentholder {
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 21000;
		display: block;
		overflow-x: visible;
		overflow-y: visible;
		width: 95%;
		height: 95%;
		margin-top: 1%;
		margin-left: 2%;
		padding: 0px;
		border-style: solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.72);
		border-radius: 5px;
		background-color: white;
	}
	.overlaycontentholder.slidein {
		left: auto;
		top: 0px;
		right: 0px;
		width: 1000px;
		height: 100%;
		margin-top: 0%;
		border-style: none none none solid;
		border-left-width: 12px;
		border-left-color: #21a8c6;
		border-radius: 0px;
		box-shadow: rgba(0, 0, 0, 0.38) -1px 0px 22px 5px;
	}
	.overlaytopmenu {
		min-height: 20px;
		margin-bottom: 0px;
		padding: 0px 0px 0px 0%;
	}
	.overlaycontentinline {
		overflow-x: auto;
		overflow-y: auto;
		width: 100%;
	}
	.h3white {
		color: $title-color;
	}
	.h4folderopen {
		padding-left: 67px;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/555f0b021f2168b86452cbe7_folder_open.svg);
		background-position: 31px 50%;
		background-size: 23px;
		background-repeat: no-repeat;
	}
	.h4folderopen.openboxed {
		margin-left: 20px;
		padding-left: 35px;
		background-position: 0px 50%;
	}
	.inlinemenuholder2 {
		width: 55px;
		height: 30px;
		float: left;
		opacity: 0;
	}
	.divarrow {
		position: static;
		width: 50px;
		height: 100%;
		min-height: 40px;
		min-width: 50px;
		float: left;
		border-right: 1px solid #dedede;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 50% 50%;
		background-size: 11px;
		background-repeat: no-repeat;
	}
	.divarrow:hover {
		background-color: rgba(158, 196, 134, 0.56);
	}
	.divarrow.arrowup {
		width: 2%;
		border-right-color: #c4c4c4;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554b61b9f52065916d243dad_arrowUp.svg);
	}
	.divarrow.divarrowl2 {
		width: 3%;
		border-right-style: none;
		opacity: 0.4;
	}
	.divarrow.divarrowl2:hover {
		background-color: transparent;
	}
	.divarrow.divarrowl2.green {
		background-position: 9px 50%;
		opacity: 1;
	}
	.listitem2 {
		width: 78%;
		height: 100%;
		min-height: 40px;
		min-width: 500px;
		padding-top: 5px;
		padding-bottom: 4px;
		float: left;
	}
	.listitem2.green {
		padding-left: 0px;
		border-left: 5px none #9ec486;
	}
	.listitem2.red {
		border-left: 5px none #bd0202;
	}
	.listitem2.indent {
		padding-left: 38px;
	}
	.datholder {
		width: 13%;
		height: 100%;
		min-height: 30px;
		min-width: 185px;
		padding-top: 5px;
		padding-right: 20px;
		padding-left: 20px;
		float: right;
		border-left: 1px solid #dedede;
	}
	html.w-mod-js *[data-ix="hide-me-scale-me"] {
		display: none;
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}
	html.w-mod-js *[data-ix="hide"] {
		display: none;
	}
	html.w-mod-js *[data-ix="hide-me"] {
		display: none;
	}
	html.w-mod-js *[data-ix="new-interaction-3"] {
		-webkit-transform: translate(1050px, 0px);
		-ms-transform: translate(1050px, 0px);
		transform: translate(1050px, 0px);
	}
	@media (max-width: 991px) {
		h3 {
			float: left;
			margin-bottom: 15px;
		}

		h4 {
			padding-left: 0px;
		}

		.button.buttonless.btncenter.btnarrow.inline {
			margin-right: 20px;
		}

		.divname {
			width: 25%;
			min-width: 110px;
		}

		.sectionclientheader {
			//padding-right: 0%;
		}

		.divinfoblock {
			width: 16%;
			margin-bottom: 15px;
		}

		.scrolcontainer {
			min-width: 1400px;
		}

		.sectionnav {
			height: auto;
			//padding-bottom: 12px;
		}

		.sectionlist.listoverlay {
			width: 70%;
		}

		.divlines {
			padding-left: 1%;
			background-image: none;
			background-position: 0% 0%;
			background-size: auto;
			background-repeat: repeat;
		}

		.divlines.divlinelevel1 {
			padding-left: 40px;
			background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
			background-position: 13px 50%;
			background-size: 10px;
			background-repeat: no-repeat;
		}

		.divlines.divlinelevel1.indented {
			padding-left: 75px;
			background-position: 43px 50%;
		}

		.divlines.divlinelevel2 {
			padding-left: 70px;
		}

		.divlines.arrowup {
			min-height: 25px;
			background-position: 12px 50%;
			background-size: 10px;
			background-repeat: no-repeat;
		}

		.searchform {
			width: 50%;
		}



		.textdate {
			margin-left: 12px;
			padding-top: 4px;
			float: left;
		}

		.textdate.texrdatebold {
			float: left;
		}

		.h4level3 {
			width: 100%;
			margin-bottom: 7px;
			padding-right: 25px;
			padding-left: 0px;
			line-height: 1.3em;
		}

		.h4level3.h4profile {
			background-position: 0px 0px;
		}

		.h4level3.h4medicine {
			background-position: 0px 0px;
		}

		.h4level3.h3doc {
			background-position: 3px 3px;
			background-size: auto 21px;
		}

		.h4process {
			padding-left: 31px;
		}

		.colorblocks {
			width: 40px;
			height: 10px;
			margin-bottom: 7px;
			margin-left: 1px;
		}

		.buttoncontextual.small.incontainer {
			height: 30px;
			min-height: 30px;
		}

		.buttoncontextual.topmenusmall {
			display: block;
			height: 30px;
			min-height: 30px;
			margin-top: 22px;
			float: left;
		}

		.tablecells.nolines.colorcell {
			padding-right: 6px;
		}

		.h5tabelheader.h5tabelsub.statsu {
			font-weight: 700;
			text-transform: uppercase;
		}

		.foldoutmenu {
			width: 70%;
		}

		.inputfield {
			width: 70%;
		}

		.inputfield.input20 {
			width: 42%;
		}

		.inputfield.input5 {
			width: 11%;
		}

		.div25 {
			width: 30%;
		}

		.txthistorie {
			overflow-x: visible;
			overflow-y: visible;
			padding-right: 17px;
		}

		.linklist {
			padding-left: 0px;
		}

		.contextmenuholder {
			width: 100%;
		}

		.foldouthome {
			display: none;
		}

		.overlaycontentholder {
			height: 100%;
		}

		.overlaycontentholder.slidein {
			width: 740px;
		}
	}
	@media (max-width: 767px) {
		body {
			padding-left: 40px;
		}

		h3 {
			text-align: left;
		}

		h4 {
			padding-left: 0%;
		}

		.button.buttonless {
			display: block;
		}

		.button.buttonless.btncenter.btnarrow {
			display: block;
			margin-right: 16px;
		}

		.button.buttonless.btnlast.btnarrowdown {
			display: none;
		}

		.letfnav {
			width: 40px;
		}

		.logo {
			margin-left: 2%;
		}

		.divname {
			width: 20%;
			min-width: 60px;
		}

		.sectionclientheader {
			//padding-right: 0%;
		}

		.cpr {
			border-right-style: none;
		}

		.divinfoblock {
			width: 17%;
			max-width: 250px;
			min-width: 150px;
		}

		.divinfoblock.fotoblock {
			width: 85px;
			min-width: 85px;
		}

		.cellleftone {
			overflow-x: hidden;
			overflow-y: hidden;
		}

		.scrolcontainer {
			min-width: 1300px;
		}

		.linkname {
			display: none;
		}

		.linkname.logud {
			display: block;
		}

		.sectionnav {
			height: auto;
			//padding-bottom: 15px;
		}

		.sectionlist.list80 {
			width: 100%;
		}

		.sectionlist.listoverlay {
			width: 100%;
		}

		.sectionlist.listoverlay.listsection {
			width: 100%;
		}

		.divlines.divlinelevel1 {
			padding-left: 7%;
		}

		.searchform {
			width: 52%;
			min-width: 100px;
		}

		.searchfield {
			width: 60%;
		}

		.divmenuicons {
			width: 40px;
		}

		.divmenuicons.home {
			width: 40px;
		}

		.subnavitem.active {
			display: none;
		}

		.navholder {
			width: 95%;
		}

		.textdate.texrdatebold {
			float: none;
		}

		.h4level3 {
			line-height: 1.3em;
		}

		.h4process {
			margin-left: 0px;
			float: left;
			background-image: none;
			background-position: 0% 0%;
			background-size: auto;
			background-repeat: repeat;
		}

		.buttoncontextual.small {
			margin-right: 10px;
		}

		.buttoncontextual.small.incontainer {
			float: none;
		}

		.buttoncontextual.topmenusmall {
			display: block;
			height: 35px;
			min-height: 35px;
			margin-top: -15px;
		}

		.h5tabelheader.h5tabelsub.statsu {
			font-weight: 700;
		}

		.foldoutmenu {
			width: 85%;
		}

		.formwrapper {
			width: 50%;
		}

		.cellstitel {
			width: 50%;
		}

		.cellstitel.slidein {
			width: 50%;
		}

		.inputfield {
			width: 85%;
		}

		.inputfield.input5 {
			width: 15%;
		}

		.div25 {
			width: 100%;
		}

		.div25.divslidein {
			width: 100%;
		}

		.foldouthome {
			left: 40px;
			display: none;
		}

		.overlaycontentholder.slidein {
			width: 550px;
		}

		.overlaycontentinline {
			overflow-x: scroll;
			overflow-y: scroll;
			max-height: 100%;
		}
	}
	@media (max-width: 479px) {
		body {
			padding-left: 30px;
		}

		h2 {
			width: auto;
			margin-top: 0px;
			float: none;
			border-bottom: 1px solid #c9c9c9;
			border-right-style: none;
			font-size: 1.5em;
			text-align: left;
		}

		h3 {
			font-size: 1.3em;
		}

		h4 {
			margin-bottom: 7px;
			padding-left: 0%;
			line-height: 1.2em;
		}

		.button {
			display: block;
			//margin-bottom: 10px;
			float: right;
		}

		.button.buttonless {
			display: block;
			padding-right: 12px;
			padding-left: 12px;
		}

		.button.buttonless.btncenter {
			display: none;
		}

		.button.buttonless.btncenter.btnarrow {
			width: 30px;
			height: 34px;
			min-width: 32px;
		}

		.button.buttonless.btncenter.btnarrow.inline {
			margin-top: 9px;
		}

		.button.buttonless.btnlast {
			display: block;
		}

		.letfnav {
			width: 33px;
			min-width: 33px;
		}

		.logo {
			margin-left: 10px;
		}

		.divname {
			width: 20%;
			min-width: 74px;
			padding-left: 0px;
		}

		.sectionclientheader {
			//padding-right: 0%;
		}

		.cpr {
			//width: 40%;
			margin-top: 0px;
			margin-bottom: 8px;
			margin-left: 0px;
			float: left;
			font-size: 1em;
			text-align: left;
		}

		.divinfoblock {
			width: 23%;
		}

		.divinfoblock.fotoblock {
			width: 85px;
		}

		.scrolcontainer {
			min-width: 1350px;
		}

		.linkname.logud {
			margin-left: 5px;
			padding-left: 5px;
		}

		.sectionnav {
			height: auto;
			//padding-bottom: 4px;
			//padding-left: 15px;
		}

		.sectionnav.inslidein {
			padding-top: 3px;
			padding-bottom: 4px;
		}

		.divlines {
			padding-left: 10px;
		}

		.divlines.divlinelevel1 {
			padding-top: 8px;
			padding-left: 33px;
		}

		.divlines.divlinelevel2 {
			padding-left: 30px;
		}

		.divlines.arrowup {
			padding-left: 34px;
		}

		.searchform {
			display: none;
		}

		.divmenuicons {
			width: 33px;
			background-size: 16px;
		}

		.divmenuicons.home {
			width: 33px;
		}



		.nameholder {
			//margin-left: 3px;
		}

		.searchiconsmall {
			display: block;
			background-size: auto 22px;
		}

		.navholder {
			overflow-x: auto;
			overflow-y: auto;
			margin-left: 0px;
		}

		.textdate {
			margin-top: 6px;
			margin-bottom: 6px;
			margin-left: 0px;
			padding-top: 7px;
		}

		.textdate.texrdatebold {
			margin-right: 3%;
			margin-left: 0px;
			float: left;
		}

		.h4level3 {
			width: auto;
			max-width: 100%;
			float: left;
			color: #7d7d7d;
			font-size: 1em;
			line-height: 1.3em;
		}

		.h4process {
			padding-top: 10px;
			padding-left: 0px;
			background-image: none;
			background-position: 0% 0%;
			background-size: auto;
			background-repeat: repeat;
			background-attachment: scroll;
		}

		.colorblocks {
			width: 50px;
			height: 7px;
			margin-left: 0px;
		}

		.h2citizen {
			width: 80%;
			padding-left: 0px;
			border-bottom-style: none;
			margin: 0;
			padding: 0;
			position: relative;
			display: inline;
		}

		.buttoncontextual.small {
			float: right;
		}

		.buttoncontextual.small.incontainer {
			display: block;
			float: left;
			clear: none;
		}

		.buttoncontextual.topmenusmall {
			margin-top: -26px;
			margin-right: 22px;
			float: right;
		}

		.tablecells {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.tablecells.terminal {
			min-height: 100px;
		}

		.h5tabelheader.h5tabelsub {
			padding-top: 9px;
			padding-left: 3px;
			font-size: 0.9em;
			line-height: 1.4em;
			font-weight: 400;
		}

		.h5tabelheader.h5tabelsub.statsu {
			font-weight: 700;
		}

		.inlineform {
			width: 100%;
			float: right;
		}

		.foldoutmenu {
			width: 100%;
		}

		.foldoutmenu.foldout20 {
			min-width: 130px;
		}

		.formwrapper {
			width: 50%;
		}

		.cellstitel {
			width: 46%;
			margin-bottom: 4px;
			float: left;
		}

		.cellstitel.status {
			width: 54%;
			float: right;
			font-weight: 700;
		}

		.cellstitel.slidein {
			width: 46%;
		}

		.inputfield {
			width: 100%;
		}

		.inputfield.input20 {
			width: 100%;
			margin-right: 2px;
		}

		.inputfield.input5 {
			width: 30%;
			margin-right: 2px;
		}

		.div25.divslidein {
			width: 100%;
		}

		.inlinemenuholder {
			float: right;
		}

		.linklist {
			float: left;
		}

		.foldouthome {
			left: 33px;
			display: none;
		}

		.overlaycontentholder {
			width: 97%;
		}

		.overlaycontentholder.slidein {
			width: 100%;
		}
	}

	.submenu-toggle {
		width: 45px;
		height: 45px;
		position: absolute;
		right: -86px;
		top: -7px;
		cursor: pointer;
		text-align: center;
		i {
			font-size: 14px;
		}
		div {
			position: absolute;
			background: #000;
			top: 18px;
			height: 1px;
			width: 14px;
			left: 13px;
			+ div {
				top: 22px;
				+ div {
					top: 26px;
					+ div {
						position: absolute;
						right: 0;
						top: 4px;
						font-size: 14px;
						left: 34px;
						height: auto;
						background-color: transparent;
						font-weight: 600;
					}
				}
			}
		}
	}
	@media (min-width: $screen-xs-min) {
		.submenu-toggle {
			right: 0;
			top: 0;
		}
	}
	@media (min-width: $screen-md-min) {
		.submenu-toggle {
			display: none;
		}
	}
	.button.buttonless.btnarrowdown {
		margin-right: 2%;
		padding-top: 4px;
		padding-right: 47px;
		padding-left: 10px;
		float: right;
		background-image: url(http://uploads.webflow.com/55489e32870d13647262f465/554a22e2f52065916d2419b7_arrowDown.svg);
		background-position: 88% 52%;
		background-size: 15px;
		background-repeat: no-repeat;
	}
	.tableheightfix {
		height: 92px;
	}
	@media (min-width: $screen-xs-min) {
		.tableheightfix {
			height: auto;
		}
	}
}

/*body::-webkit-scrollbar,
html::-webkit-scrollbar,
::-webkit-scrollbar-button {
	background-color: #dfdfdf;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar,
body::-webkit-scrollbar-button,
html::-webkit-scrollbar-button {
	background-color: #dfdfdf;
}*/

.submenu-toggle {
	position: absolute;
	top: 20px;
	right: 69px;
	width: 30px;
	height: 30px;
	background-color: #000;
	cursor: pointer;
	display: none;
}
@media (min-width: $screen-sm-min) {
	.submenu-toggle {
		display: none;
	}
}


/*.subnav {
	width: auto;
	min-width: 400px;
	float: left;
	list-style: none;
	padding: 0;
	margin: 0;
}
.subnavelm {
	height: auto;
	padding: 3px 25px 3px 0px;
	float: left;
	border-top: 1px none #e9f6f9;
	color: #828282;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0.05em;
	text-transform: none;
	position: relative;
	z-index: 1;
}
.subnavelm:hover {
	color: black;
}
.subnavelm a {
	color: #828282;
}
.subnavelm.active {
	margin-right: 25px;
	padding-right: 1px;
	border-bottom: 2px solid #9e9e9e;
	color: 000;
}
.subnavelm.active a {
	color: #000;
}
.subnavelm a:hover {
	text-decoration: none;
	color: #000;
}
.subnavelm a + ul {
	list-style: none;
	position: absolute;
	left: 0;
	top: 25px;
	background: #fff;
	margin: 0;
	padding: 0;
	display: none;
	width: 200px;
	border: 1px solid #dedede;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 7px 0px;
}
.subnavelm a + ul li {
	border-top: 1px solid #dedede;
}
.subnav > li:nth-child(3) ul {
	display: none !important;
}
.subnavelm a + ul li:first-child {
	border-top: 0;
}
.subnavelm a + ul li a,
.subnavelm.active a + ul li a {
	padding: 10px;
	display: block;
	color: #5a8989;
	text-transform: initial;
}
.subnavelm a + ul li a:hover {
	background: #e6f2df;
	color: $default-text-color;
	color: #43b02a;
	text-decoration: underline;
}
.subnavelm:hover ul {
	display: block;
}*/
