.patient-container {
	margin-right: 0;
	transition: .3s margin;
	.table {
		margin-bottom: 0;
	}
	a.with-default:hover {
	  	text-decoration: underline;
	}
	.list-container {
		.table {
			tbody {
				tr {
					td {
						padding: 7px 20px 6px;
						.list-content {
							a, small {
								top: -4px;
							}
						}
						.list-text-right {
							margin-right: 0;
						}
					}
				}
			}
			&.table-mobile-optimized {
				> tbody {
					> tr {
						> td {
							padding: 0;
						}
					}
				}
			}
		}
	}
	.patient-content {
		background: #fff;
	}
}

@media (min-width: $screen-md-min) {
	.patient-container {
		/*min-height: 90vh;*/
		.cards-active & {
			margin-right: 200px;
			transition: .3s margin;
		}
		.modal-full & {
			.cards-active & {
				margin-right: 0;
			}
		}
	}
}

.start-date-error{
  background-color: rgba(189,2,2,0.12) !important;
}

.patient-header {
	background: #d7e7cd;
	padding: 0 75px 0 20px;
	overflow: hidden;
	min-height: 86px;
	border-bottom: 1px solid #c9d1c2;
	position: relative;
	a {
		color: $default-text-color;
		cursor: pointer;
	}
	h2 {
		margin: 29px 10px 0 0;
		padding: 0 10px 1px 0;
		font-weight: bold;
		font-size: 28px;
	}
	p {
		margin-top: 0;
		font-size: 18px;
		color: #919191;
	  	float: left;
	}
	.patient-birthday-flag {
		background-image: url("../../img/da_DK.png");
		background-repeat: no-repeat;
		background-size: contain;
		min-width: 30px;
		min-height: 30px;
		float: left;
		margin-top: -3px;
		margin-left: 10px;
  	}
}

.patient-layout-compact {
    .patient-header {
	    min-height: 61px;

	    h2 {
	        margin: 18px 10px 0 0;
	        font-size: 23px;
	    }

	    p {
	        font-size: 16px;
	    }
  }
}

#patient-image-container {
	float: left;
	height: 75px;
	margin-top: 5px;
	margin-right: 5px;
}

@media (min-width: $screen-sm-min) {
	.patient-header {
		h2, p {
			float: left;
		}
		h2 {
			border-right: 1px solid #c9c9c9;
		}
		p {
			margin-top: 33px;
		}
		.patient-birthday-flag {
			margin-top: 15px;
		}
	}
    .patient-layout-compact {
	    .patient-header {
	        p {
		        margin-top: 22px;
	        }
	    }
    }
}

.toggle-cards {
	border: 1px solid #b0b0b0;
	padding: 0;
	background-color: #d7e7cd;
	width: 50px;
	height: 45px;
	position: absolute;
	top: 20px;
	right: initial;
	left: -50px;
	border-right: 0;
	outline: none;
	.toggle-cards-open,
	.toggle-cards-close {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 25px;
		&.important-cards {
			border-left: 7px solid #F3DF81;
		}
	}
	.toggle-cards-open {
		background-image: url("../../img/icons/Arrow-Left_2.svg");
	}
	.toggle-cards-close {
		background-image: url("../../img/icons/Arrow-Right_2.svg");
		display: none;
	}
	.toggle-cards-close {
		.cards-active & {
			display: block;
		}
	}
	.toggle-cards-open {
		.cards-active & {
			display: none;
		}
	}
}

.patient-layout-compact {
    .toggle-cards {
	    top: 8px;
    }
}

.table-icon-lock {
  background-image: url("../../img/icons/Lock.svg");
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  top: 5px;
  background-position: center;
  background-size: 90%;
  float: left;
  margin: 0 0 0 5px;
}

.patient-edit-details-img-preview {
  height: 70px;
  margin-top: 10px;
  margin-left: 2px;
}

.patient-edit-details-img-preview-error {
  height: 70px;
  width: 70px;
  margin-top: 10px;
  margin-left: 2px;
}

.patient-cards {
	width: 200px;
	min-height: calc(100vh - 46px);
	height: 100%;
	background: #d7e7cd;
	position: absolute;
	right: -200px;
	top: 0;
	border-left: 1px solid #b0b0b0;
	transition: .3s right;
	padding: 0 15px;
  	z-index: 1001;
	.patient-cards-inner {
		position: relative;
		width: 170px;
		overflow: hidden;
		padding-top: 15px;
	}
	.modal-dialog & {
		position: fixed;
		min-height: auto;
		height: 100vh;
	}
	.patient-cards-close {
		width: 30px;
		position: absolute;
		top: 15px;
		right: 15px;
		padding: 8px 0;
		cursor: pointer;
	}
	.cards-active &,
	.cards-active-overlay & {
		right: 0;
		transition: .3s right;
		.card {
			visibility : visible;
		}
	}
    .card-clickable {
	  cursor: pointer;
	  &:hover {
		.card-header {
		  text-decoration: underline;
		}
	  }
    }
	.card {
		background: #fff;
		border: 1px solid #b5b5b5;
		margin-bottom: 15px;
		transition: box-shadow .3s, visibility .3s;
		visibility : hidden; /* to disable text searching when panel hidden */
		.card-header {
			background: #ececec;
			padding: 5px;
			font-weight: bold;
			font-size: 12px;
		}
		.card-icon-lock {
		  background-image: url("../../img/icons/Lock.svg");
		  background-size: 16px 20px;
		  background-repeat: no-repeat;
		  background-position: right;
		  padding-right: 15px
		}
		.card-data {
			padding: 5px 10px;
			border-top: 1px solid #d9d9d9;
			&[class*="card-icon-"] {
				padding-left: 40px;
				background-repeat: no-repeat;
				background-position: 10px center;
				background-size: 20px 20px;
			}
			&.card-icon-address {
				background-image: url("../../img/icons/Home.svg");
			}
			&.card-icon-phone {
				background-image: url("../../img/icons/Telephone.svg");
			}
			&.card-icon-mobile {
				background-image: url("../../img/icons/Smartphone-3.svg");
			}
		    &.card-icon-biohazard {
			    background-image: url("../../img/icons/biohazard.svg")
			}
		    &.address-indicator {
			  padding-bottom: 0px;
			}
		  	&.no-border {
			  border-top: 0px;
			  padding-top: 0px;
			}
		}
		&.important {
			.card-header {
				background: #ef7c3f;
				color: $default-text-color;
			}
			.card-data {
				background: #fff9ba;
			}
		}
		&:hover {
			box-shadow: rgba(0,0,0,0.35) 0 0 3px 0;
			transition: box-shadow .3s;
		}
	}
	.btn {
	  border-top: 1px solid #c9c9c9;
	  border-right: 1px solid #c9c9c9;
	  border-left: 1px solid #c9c9c9;
	}
}

div.flower-graph {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: #9e9e9e
}

.radio-add-relative {
  padding-bottom: 20px;
}

.add-relative-label {
  padding-top: 10px;
  padding-bottom: 10px;
}

.add-relative-panel {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.reltive-dead-marker {
  color: #B8132F;
}

/*@media (min-width: $screen-sm-min) {
	.patient-cards {
		right: -200px;
		.patient-cards-close {
			display: none;
		}
	}
}*/

#change-professional-on-pathway {
	.alert,
	.alert-info {
		width: 100%;
		margin: 10px 0 0;
	}
}
